import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'SV-Font',
    props: {
        classType:{},
        /*字体图标的颜色*/
        color:{
            type: String,
            default: ''
        },
        /*字体图标的大小*/
        size:{
            type: String,
            default: '16'
        },
        //距右边内容的距离
        'right':{
            type: String,
            default: ''
        },
        //距左边内容的距离
        'left':{
            type: String,
            default: ''
        },
        //距上边内容的距离
        'top':{
            type: String,
            default: ''
        },
        //距下边内容的距离
        'bottom':{
            type: String,
            default: ''
        },
        // 是否字体加粗
        bold:{
            type:Boolean,
            default:false
        },
        //图标的行高
        'lineHeight':{
            type:Number || String,
            default:1
        },
        //图标使用的渐变色,主要是线性渐变，  如果需要使用其他渐变自行添加，
        'gradientRule':{},
    },
    setup(props: any) {
        //基础样式
        const baseStyles = ref({
            'color': props.color,
            'font-size': props.size + 'px',
            'margin-right': props.right + 'px',
            'margin-left': props.left + 'px',
            'margin-top': props.top + 'px',
            'margin-bottom': props.bottom + 'px',
            'font-weight': props.bold ? 'bold':'500',
            'line-height': props.lineHeight
        });
        //渐变的样式
        const gradationStyle = ref({
            'background-image': props.gradientRule ? 'linear-gradient('+ props.gradientRule +')':'',
            '-webkit-background-clip': props.gradientRule ? 'text':'',
            '-webkit-text-fill-color': props.gradientRule ? 'transparent':'',
        });
        return {baseStyles, gradationStyle}
    },
})