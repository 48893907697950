/***
 * axios 请求相关拦截功能
 * headers 额外参数描述:
 * @header {boolean} noLoading 不显示全局加载层;
 * @header {object} loadingRef 请求是否正在加载的vue ref对象, 当存在时自动将headers.noLoading设置为true;
 * @header {boolean} noErrorNotice 是否在响应为res.data.success为false时，和接口请求失败时，隐藏报错提示;
 */
import axios from 'axios'
import messageNotice from './messageNotice'
import { useRequestStore } from '@/stores/request'
import { hasPermissionByCodes } from './permission'
import { useRouter } from '@/router'
import { useRoute } from 'vue-router'
let requestAbortText = 'Request cancelled: ';
let loadingRequestCount = 0; // 有加载层的请求数量
let requestObj: any = {}; // 当前带加载层的请求对象
let requestTimer: any = null;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.baseURL = process.env.BASE_API;

// Request
axios.interceptors.request.use(
  (config: any) => {
    // 判断是否传loadingRef 
    let loadingRef = config.headers?.loadingRef;
    if (loadingRef) {
      loadingRef.value = true;
      config.headers.noLoading = true; // 有loading ref则取消当前请求的全局加载
    }
    const requestStore = useRequestStore();
    // 添加一段时间无操作退出登录的逻辑
    requestStore.registerRequestHandler(() => {
      useRouter().push({
        path: '/login'
      });
    });
    if (config.url && config.url.indexOf('/connect/token') > -1) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    } else { // 给接口请求添加token
      let token = requestStore.token;
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (config.url && config.url.indexOf('/connect/revocation') > -1) { // 退出登录接口，删除请求操作记录定时器
      requestStore.deleteRequestListener();
    } else { // 除退出登录接口外，其他接口会更新请求操作记录定时器
      requestStore.updateRequestListener();
    }
    // 是否打开加载页
    if (config.url && !config.headers.noLoading) {
      loadingRequestCount++;
      requestStore.$patch({
        isLoading: true
      });
    }
    // 取消请求方法
    let cancelReqFn: any = config.cancelToken;
    if (!config.cancelToken) {
      config.cancelToken = new axios.CancelToken(function executor(c) {
        cancelReqFn = (info: any) => {
          c(info);
          if (loadingRef) { // 如果有loading的ref，取消请求同步更新值
            loadingRef.value = false;
          }
        };
      });
    }
    // 刷新接口期间,或者接口有code并且没有该codes数组中的权限，取消相关请求
    if ((requestStore.isRefreshToken && config.url.indexOf('/connect/token') === -1) || (config.codes && !hasPermissionByCodes(config.codes))) {
      if (loadingRequestCount > 0 && !config.headers.noLoading) {
        loadingRequestCount--;
        cancelLoading(requestStore);
      }
      if (cancelReqFn) {
        cancelReqFn(requestAbortText + config.url);
      }
    } else if (!config.headers.noLoading) {
      // 带加载层的请求,全局注册该请求,以便手动关闭加载层时取消请求
      let time = new Date().getTime();
      let reqId = time + '_' + config.url;
      requestObj[reqId] = {
        id: reqId,
        url: config.url,
        cancelReqFn: cancelReqFn
      };
      // 给response添加请求的id
      config.headers.requestId = reqId;
    }
    return config;
  },
  error => {
    return Promise.reject(error)
  }
);

const errorMsgs: any = {
  403: 'Access forbidden, no API access privileges.',
  404: 'Page not found.',
  405: 'The method used to access the page is not allowed, incorrect method call.',
  500: 'Internal Server Error (ISE)',
  502: 'The web server received an invalid response while acting as a gateway or proxy server.'
};
let systemLoggedOut = new Set()
let clearTimeInterval = 0

// Response
axios.interceptors.response.use((response: any) => {
  if (response.config?.headers?.loadingRef) { // 如果传了loadingRef,则更新其值为false
    response.config.headers.loadingRef.value = false;
  }
  const requestStore = useRequestStore();
  // UNDO 刷新token逻辑  if (response.config.url.indexOf('/connect/token') === -1 && response.status !== 401) { }
  // 401 handler 
  if (response.status === 401 || (!response.data.success && response.data.code === 401) ) {
    let router = useRouter();
    router.push({ path: '/login' });
    if (response.config.url.indexOf('/Accounts/GetLoginAccountInfo') === -1) {
      let route = useRoute()
      if(route.path != '/login'){
        clearTimeout(clearTimeInterval)
        clearTimeInterval  = setTimeout(() => {
         let arr:any = Array.from(systemLoggedOut)
         messageNotice('error', arr[0]);
       }, 1000);
      }
    }
  }
  // UNDO 判断license是否过期 if (response.status === 444) {}
  // 报错提示： 当headers.noErrorNotice为true时不报错
  if (!response.data.success && errorMsgs[response.data.code] && !response.config.headers.noErrorNotice) {
    messageNotice('error', errorMsgs[response.data.code]);
  }
  // 返回成功，但接口提示success为false，弹出错误信息： 当headers.noErrorNotice为true时不报错
  if (response.data && response.data.success === false && response.data.message && !response.config.headers.noErrorNotice) {
    messageNotice('error', response.data.message);
  }
  // 删除请求注册记录
  if (response.config && response.config.headers && !response.config.headers.noLoading && response.config.headers.requestId) {
    delete requestObj[response.config.headers.requestId];
  }
  // 更新当前请求数
  if (loadingRequestCount > 0 && response.config.headers && !response.config.headers.noLoading) {
    loadingRequestCount--;
  }
  // 当前请求数为0时关闭加载页
  if (loadingRequestCount <= 0) {
    cancelLoading(requestStore);
  }
  return response;
}, error => {
  // 如果有loadingRef，则更新其值为false
  if (error?.config?.headers?.loadingRef) { 
    error.config.headers.loadingRef.value = false;
  }

  const requestStore = useRequestStore();
  // 删除请求注册记录
  if (error.config && error.config.headers && !error.config.headers.noLoading && error.config.headers.requestId) {
    delete requestObj[error.config.headers.requestId];
  }
  // 更新当前请求数
  if (loadingRequestCount > 0 && (!error.config || !error.config.headers.noLoading)) {
    loadingRequestCount--;
  }
  // 当前请求数为0时关闭加载页
  if (loadingRequestCount <= 0) {
    cancelLoading(requestStore);
  }
  const accountStrs = ['account_locked', 'account_passworderror_locked', 'account_inactive', 'account_passworderror'];

  // 当headers.noErrorNotice为true时不报错
  if (error.response && error.response.status === 400 && error.config.url.indexOf('/connect/token') === -1) {
    if (error.response.data.error == 'two_fa_error') {
      messageNotice('error', error.response.data.error_description);
    } else if (!error.config.headers.noErrorNotice) {
      messageNotice('error', 'Bad request, incorrect request parameters.');
    }
  } else if (error.response && error.response.status === 401) {
    let router = useRouter();
    router.push({ path: '/login' });
    if (!error.config || error.config.url.indexOf('/Accounts/GetLoginAccountInfo') === -1 ) {
      let route = useRoute()
      if(route.path != '/login'){
        systemLoggedOut.add('System logged out, authentication has expired.')
        clearTimeout(clearTimeInterval)
        clearTimeInterval  = setTimeout(() => {
         let arr:any = Array.from(systemLoggedOut)
         messageNotice('error', arr[0]);
       }, 1000);
      }

    }
  } else if (error.response && errorMsgs[error.response.status] && !error.config.headers.noErrorNotice) {
    messageNotice('error', errorMsgs[error.response.status]);
  } else if (error.response && error.response.data && error.response.data.error_description && accountStrs.indexOf(error.response.data.error) === -1 && !error.config.headers.noErrorNotice) {
    messageNotice('error', error.response.data.error_description);
  } else if (error && error.toString().indexOf('Network Error') > -1) {
    // 网络异常
    messageNotice('error', 'Failed to connect server');
  } else if (requestStore.isRefreshToken || (error && error.message && error.message.indexOf(requestAbortText) > -1) || (error && !error.message)) {
    console.log('No Notice');
    // 取消请求不提示
  } else if (!error || !error.response || !error.response.data || !error.response.data.error_description) {
    messageNotice('error', 'No response from server');
  }
  return Promise.reject(error);
});

function cancelLoading(requestStore: any) {
  clearTimeout(requestTimer);
  requestTimer = setTimeout(() => {
    requestStore.$patch({
      isLoading: false
    });
  }, 200);
  requestObj = {};
}

export const Methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
};

export function getQueryString(data: any) {
  let str = '';
  for (let k in data) {
    if (str.indexOf('?') === -1) {
      str = str + '?';
    }
    if (str[str.length - 1] !== '&' && str[str.length - 1] !== '?') {
      str = str + '&';
    }
    str = str + k + '=' + data[k];
  }
  return str;
}

export default axios;
