import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useAdminInfoStore = defineStore('admin', () => {

  const adminInfo: any = ref({
    id: '',
    name: '',
    permissionCodes: []
  });

  function setData(value: any) {
    adminInfo.value = value;
  }

  function getPermissionCodes(): [] {
    return adminInfo.value.permissionCodes || [];
  }

  return { adminInfo, setData, getPermissionCodes };
})

// store.$patch({
//   adminInfo: adminInfo,
// })