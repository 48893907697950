import times from './utils/times'
import moment from 'moment'

// 在多个vue template模板中频繁使用的静态数据可放到globalProperties中
const globalProperties: any = {
    Time: {
        type: 'singapore',
        china: times.china, // 中文
        singapore: times.singapore, // 新加坡
    },
    /**
     * @method formatDate 格式化日期
     * @param {Number|String} time 时间戳或时间字符串
     * @param {String} formatStr 输入的格式字符串或输出的格式字符串
     * @param {Boolean|Number} type 
     *  可选, 操作的类型. 
     *  如果不传，以当前时间格式转化; 
     *  如果传了不管当前什么格式，优先将传过来的时间转成‘日月年’
     * @param {Boolean} isSecond 可选. 是否是秒时间戳，不传则认为是毫秒时间戳。当type=1时可用
     * @example
     *  后端转前端 formatDate('', Time[Time.type].typeDate);
     *     或者 Time[Time.type].typeDateHM;
     *     或者 Time[Time.type].typeTime;
     *  前端转后端 formatDate('',Time[Time.type].typeDate,true);
     *     或者 Time[Time.type].typeDateHM;
     *     或者 Time[Time.type].typeTime
     */
    formatDate: function (time: number | string, formatStr: string, type?: any, isSecond?: boolean) {
        let str = '';
        if (!time) {
            return str;
        }
        if (typeof time === 'string') {
            if (type === true) { // 字符串转换成 年-月-日 (时:分:秒)
                str = formatTypeIsTrue(time, formatStr);
            } else if (type === 2) { // 时间格式转化成时间戳
                str = formatTypeIsTwo(time, formatStr);
            } else if (type === 3) { // 根据UTC时间获取本地时间
                str = formatTypeIsThree(time, formatStr);
            } else { // 根据年-月-日(时-分-秒)格式的时间得到指定格式的时间字符串
                str = formatDefault(time, formatStr);
            }
        } else if (typeof time === 'number' && type === 1) { // 时间戳转成指定格式时间
            str = formatTypeIsOne(time, formatStr, isSecond);
        }
        return str;
    }
}

/***
 * @method formatTypeIsTrue 字符串转换成 年-月-日 (时:分:秒)
 * @param {String} time 输入时间字符串
 * @param {String} formatStr 输入字符串的格式
 * @return {String} 对应的年-月-日 (时:分:秒)字符串
 */
function formatTypeIsTrue(time: string, formatStr: string) {
    let str: any = ''
    switch (formatStr) {
        case 'dd-MM-yyyy':
            str = moment(time, 'DD-MM-YYYY').format('YYYY-MM-DD');
            break;
        case 'yyyy-MM-dd HH:mm':
            str = moment(time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
            break;
        case 'dd-MM-yyyy HH:mm':
            str = moment(time, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            break;
        case 'yyyy-MM-dd HH:mm:ss':
            str = moment(time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            break;
        case 'dd-MM-yyyy HH:mm:ss':
            str = moment(time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            break;
        default:
        // 默认优先转成'yyyy-MM-dd'年月日格式
            str = moment(time, 'YYYY-MM-DD').format('YYYY-MM-DD');
            break;
    }
    return str
}

/***
 * @method formatTypeIsOne 时间戳转化成指定的时间格式
 * @param {Number} time 输入时间戳
 * @param {String} formatStr 输出字符串的格式
 * @param {Boolean} isSecond 是否是秒时间戳，否则是毫秒时间戳
 * @return {String} 对应的formatStr格式的时间字符串
 */
function formatTypeIsOne(time: number, formatStr: string, isSecond?: boolean) {
    let str: any = '';
    let unixSign = isSecond ? 'X' : 'x';
    switch (formatStr) {
        case 'dd-MM-yyyy':
            str = moment(time, unixSign).format('YYYY-MM-DD HH:mm:ss');
            str = moment(str, 'YYYY-MM-DD').format('DD-MM-YYYY');
            break;
        case 'yyyy-MM-dd HH:mm':
            str = moment(time, unixSign).format('YYYY-MM-DD HH:mm:ss');
            str = moment(str, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
            break;
        case 'dd-MM-yyyy HH:mm':
            str = moment(time, unixSign).format('YYYY-MM-DD HH:mm:ss');
            str = moment(str, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm');
            break;
        case 'yyyy-MM-dd HH:mm:ss':
            str = moment(time, unixSign).format('YYYY-MM-DD HH:mm:ss');
            str = moment(str, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            break;
        case 'dd-MM-yyyy HH:mm:ss':
            str = moment(time, unixSign).format('YYYY-MM-DD HH:mm:ss');
            str = moment(str, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
            break;
        case 'HH:mm dd-MM':
            str = moment(time, unixSign).format('HH:mm MM-DD');
            str = moment(str, 'HH:mm MM-DD').format('HH:mm DD-MM');
            break;
        default:
        // 默认'yyyy-MM-dd'
            str = moment(time, unixSign).format('YYYY-MM-DD HH:mm:ss');
            str = moment(str, 'YYYY-MM-DD').format('YYYY-MM-DD');
            break;
    }
    return str;
}

/***
 * @method formatTypeIsTwo 时间格式转化成时间戳
 * @param {String} time 时间字符串
 * @param {String} formatStr 输入的字符串的格式
 * @return {String} 时间戳
 */
function formatTypeIsTwo(time: string, formatStr: string) {
    let str: any = ''
    switch (formatStr) {
        case 'dd-MM-yyyy':
            str = moment(time, 'DD-MM-YYYY').format('YYYY-MM-DD');
            str = moment(str).valueOf();
            break;
        case 'yyyy-MM-dd HH:mm':
            str = moment(time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
            str = moment(str).valueOf();
            break;
        case 'dd-MM-yyyy HH:mm':
            str = moment(time, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
            str = moment(str).valueOf();
            break;
        case 'yyyy-MM-dd HH:mm:ss':
            str = moment(time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            str = moment(str).valueOf();
            break;
        case 'dd-MM-yyyy HH:mm:ss':
            str = moment(time, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            str = moment(str).valueOf();
            break;
        default:
        // 默认'yyyy-MM-dd'
            str = moment(time, 'YYYY-MM-DD').format('YYYY-MM-DD');
            str = moment(str).valueOf();
            break;
    }
    return str;
}

/***
 * @method formatTypeIsThree 根据UTC时间获取本地时间
 * @param {String} time 输入时间字符串
 * @param {String} formatStr 输出的字符串的格式
 * @return {String} 输出时间字符串
 */
function formatTypeIsThree(time: string, formatStr: any) {
    let str = "";
    let localTime = moment.parseZone(time).local();
    switch (formatStr) {
        case 'dd-MM-yyyy':
            str = localTime.format('DD-MM-YYYY');
            break;
        case 'yyyy-MM-dd HH:mm':
            str = localTime.format('YYYY-MM-DD HH:mm');
            break;
        case 'dd-MM-yyyy HH:mm':
            str = localTime.format('DD-MM-YYYY HH:mm');
            break;
        case 'yyyy-MM-dd HH:mm:ss':
            str = localTime.format('YYYY-MM-DD HH:mm:ss');
            break;
        case 'dd-MM-yyyy HH:mm:ss':
            str = localTime.format('DD-MM-YYYY HH:mm:ss');
            break;
        default:
        // 默认'yyyy-MM-dd'
            str = localTime.format('YYYY-MM-DD');
            break;
    }
    return str;
}

/***
 * @method formatDefault 根据年-月-日(时-分-秒)格式的时间得到指定格式的时间字符串
 * @param {String} time 输入时间字符串
 * @param {String} formatStr 输出的字符串的格式
 * @return {String} 输出时间字符串
 */
function formatDefault(time: string, formatStr: any) {
    let str: any = ''
    switch (formatStr) {
        case 'dd-MM-yyyy':
            str = moment(time, 'YYYY-MM-DD').format('DD-MM-YYYY');
            break;
        case 'yyyy-MM-dd HH:mm':
            str = moment(time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
            break;
        case 'dd-MM-yyyy HH:mm':
            str = moment(time, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm');
            break;
        case 'yyyy-MM-dd HH:mm:ss':
            str = moment(time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            break;
        case 'dd-MM-yyyy HH:mm:ss':
            str = moment(time, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
            break;
        default:
        // 默认'yyyy-MM-dd'
            str = moment(time, 'YYYY-MM-DD').format('YYYY-MM-DD');
            break;
    }
    return str;
}

export default function addGlobalProperties(app: any) {
    let k: any = '';
    for (k in globalProperties) {
        app.config.globalProperties[k] = globalProperties[k];
    }
}