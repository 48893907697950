/***
 * loading指令
 * 样式依赖公共样式class: sv-loading-box-full、sv-loading-box-cover、sv-loading-svg、sv-loading-circle.
 * 
 * directive参数说明:
 * @property {Boolean | Object} directive.value 如果为boolean, 则为是否显示加载层; 否则为具体的选项对象.
 * directive.value参数说明:
 * @property {Number} directive.value.size 加载图标尺寸.
 * @property {String} directive.value.color 图标颜色,默认为主题色.
 * @property {String} directive.value.background 背景色.
 * @property {Number} directive.value.borderRadius 背景圆角.
 * @property {Boolean} directive.value.showText 是否显示加载文本.
 * @property {String} directive.value.text 加载文本, 默认为'Loading...'.
 * @property {Number} directive.value.textSize 加载文本字体大小.
 * @property {Number} directive.value.textColor 加载文本字体颜色.
 * @property {Number} directive.value.loading 是否显示加载层.
 * @property {Number} directive.value.zIndex 加载层css定位层级.
 * 使用示例:
 *      <sv-button right="24" default v-loading="true">Save</sv-button> 
 *      <sv-button right="24" default v-loading="{ color: '#ff0000', loading: isLoading }">Save</sv-button> 
 */

import { isObject } from '@vue/shared';

const INSTANCE_KEY = Symbol("SvLoading");

const getLoadingContent = () => {
  return `
    <svg width="36" height="36" style="width: 1em; height: 1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <style>
          .sv_spinner_OSmW {
              transform-origin:center;
              animation: rotate1234 .75s step-end infinite;
          }

          @keyframes rotate1234 {
              8.3% {
                  transform: rotate(30deg);
              }
              16.6% {
                  transform: rotate(60deg);
              }
              25% {
                  transform: rotate(90deg);
              }
              33.3% {
                  transform: rotate(120deg);
              }
              41.6% {
                  transform: rotate(150deg);
              }
              50% {
                  transform: rotate(180deg);
              }
              58.3% {
                  transform: rotate(210deg);
              }
              66.6% {
                  transform: rotate(240deg);
              }
              75% {
                  transform: rotate(270deg);
              }
              83.3% {
                  transform: rotate(300deg);
              }
              91.6% {
                  transform: rotate(330deg);
              }
              100% {
                  transform: rotate(360deg);
              }
          }
      </style>
      <g class="sv_spinner_OSmW">
        <rect x="11" y="1" width="2" height="5" opacity=".14" fill="currentColor" />
        <rect x="11" y="1" width="2" height="5" transform="rotate(30 12 12)" fill="currentColor" opacity=".29"/>
        <rect x="11" y="1" width="2" height="5" transform="rotate(60 12 12)" fill="currentColor" opacity=".43"/>
        <rect x="11" y="1" width="2" height="5" transform="rotate(90 12 12)" fill="currentColor" opacity=".57"/>
        <rect x="11" y="1" width="2" height="5" transform="rotate(120 12 12)" fill="currentColor" opacity=".71"/>
        <rect x="11" y="1" width="2" height="5" transform="rotate(150 12 12)" fill="currentColor" opacity=".86"/>
        <rect x="11" y="1" width="2" height="5" transform="rotate(180 12 12)" fill="currentColor" />
      </g>
    </svg>
  `;
};

const createLoading = (options: any) => {
  let target: any = options.target;
  if (getComputedStyle(target)['position'] === 'static') {
    target.style.position = 'relative';
  }
  let loadingDom = document.createElement('div');
  loadingDom.setAttribute('class', 'sv-loading-box-full sv-loading-box-cover');
  loadingDom.innerHTML = getLoadingContent();
  target.appendChild(loadingDom);
  // 加载文本
  let textDom: any = null;
  let output = {
    options: options,
    element: loadingDom,
    textElement: textDom,
    show: (options: any) => {
      loadingDom.style.display = 'flex';
      loadingDom.style.zIndex = options.zIndex;
      loadingDom.style.color = options.color; // 图标颜色
      loadingDom.style.borderRadius = options.borderRadius + 'px';
      loadingDom.style.fontSize = options.size + 'px'; // 图标大小
      loadingDom.style.backgroundColor = options.background;
      if (options.showText) {
        if (!textDom) {
          textDom = document.createElement('span');
          textDom.style.paddingTop = '8px';
          loadingDom.appendChild(textDom);
        }
        textDom.style.fontSize = options.textSize + 'px';
        textDom.style.color = options.textColor;
        textDom.innerText = options.text;
      }
      options.loading = true;
    },
    close: (options: any) => {
      loadingDom.style.display = 'none';
      options.loading = false;
    }
  };

  if (!options.loading) {
    output.close(options);
  } else {
    output.show(options);
  }

  return output;
}

const createInstance = (el: any, binding: any, isObjectType?: boolean) => {
  let _a, _b, _c;
  const getBindingProp = (key: any) => isObject(binding.value) ? binding.value[key] : void 0;
  const fullscreen = (_a = getBindingProp("fullscreen")) != null ? _a : binding.modifiers.fullscreen;
  const options = {
    target: (_b = getBindingProp("target")) != null ? _b : fullscreen ? void 0 : el,
    body: (_c = getBindingProp("body")) != null ? _c : binding.modifiers.body,
    size: 36, // 加载图标尺寸
    color: 'var(--main-color)', // 图标颜色
    background: 'rgba(255, 255, 255, 1)', // 背景色
    borderRadius: 0,
    showText: true, // 是否显示加载文本
    text: 'Loading...', // 加载文本 
    textSize: 16, // 文本大小
    textColor: '#333', // 加载文本颜色
    loading: false,
    zIndex: 99999
  };
  if (isObjectType) {
    let bindVal = binding.value;
    if (typeof bindVal.size === 'number') {
      options.size = bindVal.size;
    }
    if (typeof bindVal.color === 'string') {
      options.color = bindVal.color;
    }
    if (typeof bindVal.background === 'string') {
      options.background = bindVal.background;
    }
    if (typeof bindVal.borderRadius === 'number') {
      options.borderRadius = bindVal.borderRadius;
    }
    if (typeof bindVal.showText === 'boolean') {
      options.showText = bindVal.showText;
    }
    if (typeof bindVal.text === 'string') {
      options.text = bindVal.text;
    }
    if (typeof bindVal.textSize === 'number') {
      options.textSize = bindVal.textSize;
    }
    if (typeof bindVal.textColor === 'string') {
      options.textColor = bindVal.textColor;
    }
    if (typeof bindVal.zIndex === 'number') {
      options.zIndex = bindVal.zIndex;
    }
    options.loading = bindVal.loading || false;
  } else {
    options.loading = binding.value;
  }

  if (el[INSTANCE_KEY]) {
    el[INSTANCE_KEY].options = options;
    el[INSTANCE_KEY].instance.show(options);
  } else {
    el[INSTANCE_KEY] = {
      options,
      instance: createLoading(options)
    };
  }
};

const loadingDirective = {
  mounted(el: any, binding: any) {
    if (typeof binding.value === 'boolean' && binding.value) {
      createInstance(el, binding);
    } else if (typeof binding.value === 'object' && binding.value && binding.value.loading) {
      createInstance(el, binding, true);
    }
  },
  updated(el: any, binding: any) {
    const instance = el[INSTANCE_KEY];
    if (binding.oldValue !== binding.value) {
      if (typeof binding.value === 'boolean' && binding.value) {
        createInstance(el, binding);
      } else if (typeof binding.value === 'object' && binding.value && binding.value.loading) {
        createInstance(el, binding, true);
      } else if (instance) {
        instance.instance.close(instance.options);
      }
    }
  },
  unmounted(el: any) {
    if (el[INSTANCE_KEY]) {
      el[INSTANCE_KEY].instance.close(el[INSTANCE_KEY].options);
    }
  }
};

export default loadingDirective;
