/***
 * 基于el-table-v2二次封装的table组件，依赖sv-ellipsis组件(用于显示超出文本移入提示)以及el-checkbox组件.
 * @prop {object} defaultSort 给默认排序字段赋值，类型为{sortType, sortName};
 * @prop {boolean} loading 是否正在加载;
 * @prop {string} baseURL column.type=image时,图片显示的路径前缀;
 * @prop {any} [other] 支持el-table-v2的所有属性;
 * @event 'selection-change' el-checkbox选择框勾选的值改变时触发;
 * @event 'column-sort' 列表顶部表头点击排序时触发;
 * @event [other] 支持el-table-v2的所有事件;
 * @column {string} type 当值为'selection'时, 启用复选框; 当为'image'时，使用sv-show-image渲染图片;
 * @column {number} width 宽度属性;
 * @column {string} fixed 是否固定列;
 * @column {number} minWidth 最小宽度属性;
 * @column {number} maxWidth 最大宽度属性;
 * @column {boolean} ellipsis 当值为true时, 使用sv-ellipsis组件显示超出文本;
 * @column {string} placement sv-ellipsis组件的placement属性;
 * @column {boolean} disableResize 当该值为true时，它的宽度是固定的，不会随着table宽度改变;
 */

import { defineComponent, ref, unref, watch, onUnmounted, nextTick } from 'vue'
import { ElCheckbox } from 'element-plus'
import svEllipsis from '../sv-ellipsis'
import svShowImage from '../sv-show-image'

import type { FunctionalComponent } from 'vue'
import type { CheckboxValueType } from 'element-plus'

export default defineComponent({
  name: 'sv-el-table-v2',
  components: {
    svEllipsis,
    svShowImage
  },
  props: {
    // 支持v2.4.2所有el-table-v2的属性和事件 ( 通过$attrs将属性和方法透传给el-table-v2 )
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    // 默认排序
    defaultSort: {
      type: Object,
      default() {
        return {
          sortName: '',
          sortType: 'normal'
        };
      }
    },
    // 是否正在加载
    loading: {
      type: Boolean,
      deafault: false
    },
    baseURL: {
      type: String,
      default: '/file/api/v1/Files/Get/'
    }
  },
  emits: [
    // el-table-v2的事件不能在此声明，否则该组件无法将el-table-v2的事件传递出去
    // 自定义事件
    // 'selection-change', 当selection选择改变时触发
    'selection-change',
    // 排序事件
    'column-sort'
  ],
  setup(props: any, context: any) {

    // 监听宽度变化，计算列宽度
    let tableWidth = 300;
    // let tableHeight = 700;
    let resizerTimer: any = null;
    let rendered = ref(false);
    const isComputing = ref(true);
    let computeTimer: any = null;

    let handleResize = (info: any) => {
      if (isComputing.value) { // 防止重新渲染也会调用该方法
        return;
      }
      tableWidth = info.width;
      clearTimeout(resizerTimer);
      resizerTimer = setTimeout(() => {
        tableColumns.value = computeColumnWidth();
        nextTick(() => {
          if (tableRef.value) {
            tableRef.value.scrollToLeft(0);
          }
        });
      }, 500);
    }

    onUnmounted(() => {
      clearTimeout(resizerTimer);
    });

    // 表格数据处理

    let tableRef: any = ref(null);
    let tableData: any = ref([]);
    watch(() => props.data, (newVal: any[]) => {
      tableData.value = newVal;
    }, {
      immediate: true
    });

    // 获取已选项
    const getSelectionRows = () => tableData.value.filter((item: any) => item.checked);

    // 表头处理
    let tableColumns: any = ref([]);

    type SelectionCellProps = {
      value: boolean
      intermediate?: boolean,
      disabled?: boolean,
      onChange: (value: CheckboxValueType) => void
    }

    // 选择框组件
    const SelectionCell: FunctionalComponent<SelectionCellProps> = (params: any) => {
      return (
        <ElCheckbox
          onChange={params.onChange}
          modelValue={params.value}
          disabled={params.disabled}
          indeterminate={params.intermediate}
        />
      )
    }

    // 渲染checkbox勾选
    const renderSelection = (item: any) => {
      item.width = 50;
      item.key = 'selection';
      item.dataKey = 'selection';
      // 表格数据渲染
      item.cellRenderer = ({ rowData }: any) => {
        const onChange = (value: CheckboxValueType) => {
          rowData.checked = value;
          context.emit('selection-change', getSelectionRows());
        };
        return <SelectionCell value={rowData.checked} onChange={onChange} disabled={rowData.disabled}/>
      }
      // 表头数据渲染
      item.headerCellRenderer = () => {
        const _data = unref(tableData);
        const onChange = (value: CheckboxValueType) => { 
          tableData.value = _data.map((row: any) => {
            if (!row.disabled) {
              row.checked = value;
            }
            return row;
          });
          context.emit('selection-change', getSelectionRows());
        }
        const hasData = _data.length > 0;
        const allSelected = _data.every((row: any) => row.disabled || row.checked) && hasData;
        const containsChecked = _data.some((row: any) => row.checked);
        return (
          <SelectionCell
            value={allSelected}
            intermediate={containsChecked && !allSelected}
            onChange={onChange}
          />
        )
      }
    }

    // 自定义排序(column的sortable为true时启用, 排序名用sortName, 若没有则取dataKey属性)
    let sortInfo: any = ref({
      sortName: props.defaultSort.sortName,
      sortType: props.defaultSort.sortType
    });
    const renderSortHeader = (item: any) => {
      let orderData: any = {
        0: 'asc',
        1: 'desc',
        'normal': 'normal'
      };
      item.headerCellRenderer = () => {
        let sortName = item.sortName || item.dataKey || item.slot;
        const handleSort = (sortType: any) => {
          sortInfo.value = {
            sortName: sortName,
            sortType: sortType
          };
          if (sortType === 'normal') {
            sortType = undefined;
            sortName = undefined;
          }
          context.emit('column-sort', {
            sortName: sortName,
            sortType: sortType,
            order: orderData[sortType],
            prop: sortName
          });
        }
        const onClickUp = (ev: Event) => {
          ev.stopPropagation();
          handleSort(sortInfo.value.sortName === sortName && sortInfo.value.sortType === 0 ? 'normal' : 0);
        }
        const onClickDown = (ev: Event) => {
          ev.stopPropagation();
          handleSort(sortInfo.value.sortName === sortName && sortInfo.value.sortType === 1 ? 'normal' : 1);
        }
        const onClickHeader = () => {
          let output: string|number = 0;
          if (sortInfo.value.sortName === sortName) {
            output = sortInfo.value.sortType === 1 ? 0 : 1;
          }
          handleSort(output);
        }
        const classObj: any = {
          'caret-wrapper': true,
          'ascending': sortInfo.value.sortName === sortName && sortInfo.value.sortType === 0,
          'descending': sortInfo.value.sortName === sortName && sortInfo.value.sortType === 1,
        }
        return (
          <span class="el-table" style="color: inherit" onClick={onClickHeader}>
            <span class="el-table-v2__header-cell-text" title={item.title}>{ item.title }</span>
            <span class={classObj}>
              <i class="sort-caret ascending" onClick={onClickUp}></i>
              <i class="sort-caret descending" onClick={onClickDown}></i>
            </span>
          </span>
        )
      }
    }
  
    // 渲染照片
    const renderImage = (item: any) => {
      if (!item.width) {
        item.width = 70;
      }
      item.cellRenderer = ({ rowData }: any) => {
        if (typeof rowData._base64Data === 'undefined') {
          rowData._base64Data = '';
        }
        return (
          <sv-show-image 
            v-model:base64Data={rowData._base64Data} 
            url={ props.baseURL + rowData[item.dataKey]} 
            objectFit={item.objectFit || 'contain'} 
            detailUrl={item.detailUrl || ''}
            className={item.className || ''}
          ></sv-show-image>
        );
      }
    }

    // 动态计算表格宽度
    function computeColumnWidth(list?: any) {
      if (!list) {
        list = tableColumns.value;
      }
      let curColWidth = 0;
      let reservedWidth: number = tableWidth - 40;
      let totalWidthRate = 0;
      let arr: any = list.filter((item: any) => {
        curColWidth = curColWidth + item._width;
        // disableResize为true, 或者固定列, 或者为selection或operation时，不会重置宽度
        if ( item.type === 'selection' || item.type === 'operation' || item.disableResize || item.fixed ) {
          reservedWidth = reservedWidth - item._width;
          return false;
        } else {
          totalWidthRate += item._width;
          return true;
        }
      });
      if (curColWidth < tableWidth) {
        computeColumnWidthDo(arr, reservedWidth, totalWidthRate, 0);
      }
      return list;
    }
    
    /***
     * 动态分配表格宽度实现
     * @param {Array} list 需要动态计算宽度的表格列表.
     * @param {Number} totalWidth 用于动态计算宽度部分的总宽度.
     * @param {Number} totalWidthRate 用于动态计算宽度部分的各列设置的 width 的和.
     */
    const COLUMN_MIN_WIDTH = 30;
    function computeColumnWidthDo(list: any[], totalWidth: number, totalWidthRate: number, calcCount: number) {
      let disValue = 0;
      let newList: any = [];
      let hasAdjusted = false; 
      let everyLimit = list.every((item: any) => (typeof item.minWidth === 'number') || typeof item.maxWidth === 'number');
      list.forEach((item: any) => {
        let w = Math.floor(item._width / totalWidthRate * totalWidth);
        let adjusted = false; 
        if (!everyLimit && item.minWidth && w < item.minWidth) {
          disValue = disValue - (item.minWidth - w);
          w = item.minWidth;
          adjusted = true;
          totalWidth = totalWidth - w;
          totalWidthRate = totalWidthRate - item._width;
        } 
        if (!everyLimit && item.maxWidth && w > item.maxWidth) {
          disValue = disValue + ( w - item.maxWidth);
          w = item.maxWidth;
          adjusted = true;
          totalWidth = totalWidth - w;
          totalWidthRate = totalWidthRate - item._width;
        }
        if (!adjusted) {
          newList.push(item);
        } else {
          hasAdjusted = true;
        }
        if (w < 0) {
          w = COLUMN_MIN_WIDTH;
        }
        item.width = w;
      }); 
      if (hasAdjusted && calcCount < 10) {
        computeColumnWidthDo(newList, totalWidth, totalWidthRate, calcCount + 1);
      }
    }

    // 监听属性,更新列数据
    watch(() => props.columns, (newVal: any[]) => {
      let arr: any[] = JSON.parse(JSON.stringify(newVal)); 
      arr.forEach((item: any, index: number) => {
        if (newVal[index].headerCellRenderer) {
          item.headerCellRenderer = newVal[index].headerCellRenderer;
        }
        if (newVal[index].cellRenderer) {
          item.cellRenderer = newVal[index].cellRenderer;
        }
        if (item.type === 'selection') {
          renderSelection(item);
        } else {
          if (item.type === 'image') {
            renderImage(item);
          }
          if (item.sortable && !item.headerCellRenderer) {
            renderSortHeader(item);
          }
        } 
        if (!item.key) {
          item.key = item.dataKey || item.slot;
        }
        // width是必需的
        if ( !(item.width && typeof item.width === 'number' && item.width > 0) ) {
          if (item.minWidth && typeof item.minWidth === 'number' && item.minWidth > 0) {
            item.width = item.minWidth;
          } else {
            item.width = 120;
          }
        } else {
          // width < minWidth，自动把minWidth赋值给width
          if (item.minWidth && typeof item.minWidth === 'number' && item.minWidth > 0 && item.width < item.minWidth) {
            item.width = item.minWidth;
          }
          // width > minWidth，自动把maxWidth赋值给width
          if (item.maxWidth && typeof item.maxWidth === 'number' && item.width > item.maxWidth) {
            item.width = item.maxWidth;
          }
        }
        item._width = item.width;
      });
      tableColumns.value = computeColumnWidth(arr);
      rendered.value = false;
      isComputing.value = true;     
      nextTick(() => {
        rendered.value = true;
        clearTimeout(computeTimer);
        computeTimer = setTimeout(() => {
          isComputing.value = false;
        }, 240);
      });
    }, {
      immediate: true
    });

    return {
      rendered,
      handleResize,
      isComputing,
      tableRef,
      tableData,
      tableColumns,
      sortInfo,
      getSelectionRows,
    }
  },
});