<template>
    <el-auto-resizer :on-resize="handleResize" v-if="rendered">
        <template #default="{ height, width }">
            <!-- 自定义v-loading指令 -->
            <el-table-v2 class="sv-el-table-v2" :class="{'is-computing': isComputing }" ref="tableRef" :columns="tableColumns" :data="tableData" :width="width" :height="height" fixed
                v-bind="$attrs" v-loading="{ loading: loading, showText: true }">
                <template #empty>
                    <div style="height: 100%;" v-show="!loading">
                        <slot name="empty">
                            <div class="sv-el-table-empty">
                                <img src="./assets/no-data.png" />
                                <span>No Data</span>
                            </div>
                        </slot>
                    </div>
                </template>
                <template #cell="{ column, columnIndex, rowData, rowIndex }">
                    <slot :name="column.slot" :column="column" :columnIndex="columnIndex" :row="rowData" :index="rowIndex">
                        <div style="width: 100%" v-if="column.ellipsis">
                            <sv-ellipsis :content="rowData[column.dataKey]"
                                :placement="column.placement || 'bottom'"></sv-ellipsis>
                        </div>
                        <template v-else-if="column.type !== 'operation' && column.type !== 'tableSet' && column.dataKey">
                            <template v-if="column.dataKey && (rowData[column.dataKey] || rowData[column.dataKey] === 0)">{{
                                rowData[column.dataKey] }}</template>
                            <template v-else>-</template>
                        </template>
                        <template v-else></template>
                    </slot>
                </template>
            </el-table-v2>
        </template>
    </el-auto-resizer>
</template>

<script lang="tsx" src="./sv-el-table-v2.tsx"></script> 

<style lang="scss">
.sv-el-table-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #aaa;
    font-size: 16px;

    img {
        width: 90px;
    }
}

.sv-el-table-v2 {
    &.is-computing {
        .el-table-v2__header-cell,
        .el-table-v2__row-cell {
            visibility: hidden;
        }
    }
    .el-table-v2__right {
        right: -8px;
    }
}
</style>