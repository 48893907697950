import { ref } from 'vue'
import { defineStore } from 'pinia'
import dataDir from '@/utils/data'

export const useMenuStore = defineStore('menu', () => {

  const expend = ref(false)

  const menu = ref(dataDir.menuData);

  const curRoute = ref({
    path: '/',
    name: '',
    isFullScreenChild: false
  } as any);

  return { 
    expend, 
    menu,
    curRoute
  };
})



