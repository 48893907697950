/***
 * 简单的事件监听和处理器注册API
 * @method on 添加指定类型事件监听处理器.
 * @method off 移除指定类型事件监听处理器. 可选择移除指定事件类型的所有处理器或某一个处理器.
 * @method emit 触发指定类型事件. 支持传入一个数据参数, 要传入多个数据参数可选择传 object 类型.
 */

let listeners: any = {};

/***
 * 添加指定类型事件监听处理器
 * @param {String} eventType 事件类型名.
 * @param {Function} callback 事件处理器, 支持传入一个回调参数.
 */
const onFn = (eventType: string, callback: (params?: any) => void) => {
    if (listeners[eventType] instanceof Array === false) {
        listeners[eventType] = [];
    }
    if (listeners[eventType].indexOf(callback) === -1) {
        listeners[eventType].push(callback);
    }
}

export const on = onFn;

/***
 * 移除指定类型事件监听处理器
 * @param {String} eventType 事件类型名.
 * @param {Function} [callbackHandler] 事件处理器. 可选, 当不存在时, 删除所有 eventType 类型的事件处理器; 否则删除传入的该事件类型下注册的处理器.
 */
const offFn = (eventType: string, callbackHandler?: (params?: any) => void) => {
    if (listeners[eventType] instanceof Array) {
        if (callbackHandler) {
            let index = listeners[eventType].indexOf(callbackHandler);
            if (index > -1) {
                listeners[eventType].splice(index, 1);
            }
        } else {
            delete listeners[eventType];
        }
    }
}

export const off = offFn;

/***
 * 触发指定类型事件
 * @param {String} eventType 要触发的事件类型名.
 * @param {any} params 要传递的数据,可以是任意类型.
 */
const emitFn = (eventType: string, params?: any) => {
    if (!listeners[eventType]) {
        return;
    }
    listeners[eventType].forEach((callback: (params?: any) => void) => {
        callback(params);
    });
}

export const emit = emitFn;

export default {
    on: onFn,
    off: offFn,
    emit: emitFn
}