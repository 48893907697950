import { defineComponent, ref } from "vue";
import svSvgIcon from '../sv-svg-icon'

export default defineComponent({
    name: "sv-show-image",
    components: { svSvgIcon },
    emits: ['update:base64Data', 'error'],
    props: {
        // 图片的完整的地址，即接口请求地址或base64图片
        url: {
            type: String,
            default: '',
        },
        // 直接传base64图片.当图片请求成功后，可根据更新父组件该属性
        base64Data: {
            type: String,
            default: '',
        },
        // 大图显示Url
        detailUrl: {
            type: String,
            default: '',
        },
        // 是否可点击显示大图
        showBigImg: {
            type: Boolean,
            default: true,
        },
        // 图片的css宽
        width: {
            type: String,
            default: '50px',
        },
        // 图片的css高
        height: {
            type: String,
            default: '60px',
        },
        // 图片的css显示规则
        objectFit: {
            type: String,
            default: 'cover',
        },
        // 自定义图片的属性名
        className: {
            type: String,
            default: 'user-photo',
        }
    },
    setup(props: any, context: any) {

        /***
         * 图片加载完成, 获取其base64图片, 并通知父组件更新 base64Data 属性为该base64图片数据, 以减少请求次数;
         * 比如虚拟滚动渲染的一个列表的数据, 可为列表每一项添加一个属性, 与 base64Data 绑定, 当图片请求成功时, 通过 v-model:base64Data 自动更新base64Data属性,
         * 当该图片组件被销毁后, 第二次渲染会自动取 base64Data 的图片数据, 而不会再次通过 url 属性的图片地址再次请求.
         */

        let imageRef: any = ref(null);
        let imgLocalData: any = props.base64Data; // 图片base64数据
        let urlLocalData: string = props.url;

        function getBase64Image(url: any, callback: (base64: string) => void) {
            let img = new Image(url);
            img.crossOrigin = 'Anonymous';
            img.onload = function() {
                let canvas: any = document.createElement("canvas");
                canvas.style.width = img.width;
                canvas.style.height = img.height;
                canvas.width = img.width;
                canvas.height = img.height;
                canvas.crossOrigin = 'anonymous';
                let ctx: any = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, img.width, img.height);
                let dataURL = canvas.toDataURL("image/png");
                callback(dataURL);
            };
            img.src = url;
        }

        const handleImageLoaded = () => {
            if (props.url && urlLocalData !== props.url) {
                urlLocalData = props.url;
                getBase64Image(props.url, (imgStrData: string) => {
                    if (imgLocalData !== imgStrData) {
                        imgLocalData = imgStrData;
                        context.emit('update:base64Data', imgLocalData);
                    }
                });
            }
        }

        // 图片请求失败
        const handleImageError = (e: any) => {
            e.target.src = require('./img/default-user.png');
            context.emit('error');
        }

        /***
         * 查看大图
         */
        let previewModal: any = ref(false);
        let previewUrl: any = ref('');
        const handleShowBigImage = () => {
            if (props.showBigImg) {
                let url: any = '';
                if (props.detailUrl) {
                    url = props.detailUrl;
                } else if (props.base64Data) {
                    url = props.base64Data;
                } else {
                    url = props.url;
                }
                if (url) {
                    previewModal.value = true;
                    previewUrl.value = url;
                }
            }
        }

        return {
            imageRef,
            handleImageLoaded,
            handleImageError,
            handleShowBigImage,
            previewModal,
            previewUrl
        };
    }
});