import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50719e12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"32px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecycleScroller = _resolveComponent("RecycleScroller")!

  return (_openBlock(), _createBlock(_component_RecycleScroller, {
    ref: "scroller",
    class: "sv-vue-scroller",
    direction: _ctx.direction,
    items: _ctx.data,
    "item-size": _ctx.itemSize,
    "key-field": _ctx.keyField,
    emitUpdate: true,
    onUpdate: _ctx.handleUpdate,
    onScrollStart: _ctx.handleScrollStart,
    onScrollEnd: _ctx.handleScrollEnd
  }, {
    default: _withCtx(({item, index, active}) => [
      _renderSlot(_ctx.$slots, "default", {
        item: item,
        index: index,
        active: active
      }, () => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(item.name), 1)
      ], true)
    ]),
    _: 3
  }, 8, ["direction", "items", "item-size", "key-field", "onUpdate", "onScrollStart", "onScrollEnd"]))
}