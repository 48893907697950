import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    disabled: _ctx.disabled,
    ref: "svButton",
    class: _normalizeClass([_ctx.classObject, "sv-btn"]),
    style: _normalizeStyle(_ctx.styleObject)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 14, _hoisted_1))
}