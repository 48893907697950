import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-047b5df7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sv-ellipsis-wrapper" }
const _hoisted_2 = {
  key: 0,
  ref: "tagContainerRef",
  class: "sv-ellipsis-tag-container"
}
const _hoisted_3 = {
  key: 0,
  class: "sv-more-tag"
}
const _hoisted_4 = { class: "sv-ellipsis-tag-popover" }
const _hoisted_5 = { class: "sv-ellipsis-tag-popover_li" }
const _hoisted_6 = { class: "sv-ellipsis-txt" }
const _hoisted_7 = { class: "sv-ellipsis-txt" }
const _hoisted_8 = {
  key: 0,
  class: "sv-ellipsis-manualView-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.moreTag)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.contentData && _ctx.contentData.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentData, (item, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("span", {
                    key: index,
                    class: "sv-ellipsis-tag"
                  }, [
                    _renderSlot(_ctx.$slots, "tagContent", {
                      item: item,
                      index: index
                    }, () => [
                      _createTextVNode(_toDisplayString(_ctx.contentDataLabel ? item[_ctx.contentDataLabel] : item), 1)
                    ], true)
                  ], 512)), [
                    [_vShow, index < (_ctx.contentData.length - _ctx.moreTagCount)]
                  ])
                }), 128)),
                (_ctx.moreTagCount > 0)
                  ? (_openBlock(), _createBlock(_component_el_popover, {
                      key: 0,
                      placement: _ctx.placement,
                      width: _ctx.moreTagBoxWidth,
                      trigger: _ctx.trigger,
                      "popper-class": "sv-popper sv-popper-arrow"
                    }, {
                      reference: _withCtx(() => [
                        (_ctx.moreTagCount > 0)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "+ " + _toDisplayString(_ctx.moreTagCount), 1))
                          : _createCommentVNode("", true)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentData, (item, index) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                              (index > _ctx.contentData.length - _ctx.moreTagCount - 1)
                                ? _renderSlot(_ctx.$slots, "moreTagContent", {
                                    key: 0,
                                    item: item,
                                    index: index
                                  }, () => [
                                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.contentDataLabel ? item[_ctx.contentDataLabel] : item), 1)
                                  ], true)
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ])
                      ]),
                      _: 3
                    }, 8, ["placement", "width", "trigger"]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [], 64))
              ], 512))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_el_tooltip, _mergeProps(_ctx.objectOfAttrs, {
            content: _ctx.content,
            disabled: _ctx.isDisabled,
            placement: _ctx.placement
          }), {
            content: _withCtx(() => [
              _renderSlot(_ctx.$slots, "tooltipText", {}, undefined, true)
            ]),
            default: _withCtx(() => [
              (!_ctx.isMultipleLine)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "sv-ellipsis",
                    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCode($event))),
                    style: _normalizeStyle({ 'width': _ctx.initWidth, 'lineHeight': _ctx.lineHeight ,'text-align':_ctx.textAlign})
                  }, [
                    _renderSlot(_ctx.$slots, "default", {}, () => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.content || _ctx.content === '0' ? _ctx.content : '-'), 1)
                    ], true)
                  ], 36))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "sv-ellipsis-multiple-line",
                    onMouseenter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showLineCode($event))),
                    style: _normalizeStyle({ '-webkit-line-clamp': _ctx.initLineClamp, 'lineHeight': _ctx.lineHeight })
                  }, [
                    _renderSlot(_ctx.$slots, "default", {}, () => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.content || _ctx.content === '0' ? _ctx.content : '-'), 1)
                    ], true)
                  ], 36))
            ]),
            _: 3
          }, 16, ["content", "disabled", "placement"]),
          (_ctx.showAllText)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _renderSlot(_ctx.$slots, "btn", { expand: _ctx.expand }, () => [
                  _createElementVNode("i", {
                    class: "arrow ivu-icon ivu-icon-ios-arrow-down",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.expand = !_ctx.expand))
                  })
                ], true)
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}