import { getAdminInfo, login, loginOut } from "@/api/auth/auth";
import { useAdminInfoStore } from "@/stores/adminInfo";
import { useRequestStore } from '@/stores/request'
import { useMenuStore } from '@/stores/menu'
import { connectSignalr, destroySignalr } from "@/api/notifications/notifications";
import data from '@/utils/data'
import router from '@/router'

// 添加刷新 token 任务
let tokenUpdateTimer: any = null;
function addRefreshAccessTokenTask() {
  clearTimeout(tokenUpdateTimer);
  let date = new Date().getTime();
  const requestStore = useRequestStore();
  let updateTime = requestStore.expireTime;
  if (updateTime && date < updateTime) {
    let duration = updateTime - date;
    tokenUpdateTimer = setTimeout(() => {
      refreshAccessToken();
    }, duration);
  }
}

// 刷新token
function refreshAccessToken() {
  const requestStore = useRequestStore();
  const adminInfoStore = useAdminInfoStore();
  if (!requestStore.isRefreshToken && requestStore.refreshToken) {
    requestStore.isRefreshToken = true;
    let datas = new URLSearchParams({
      grant_type: "refresh_token",
      client_id: data.APP_AUTH_NAME,
      client_secret: 'secret',
      refresh_token: requestStore.refreshToken,
      scope: 'offline_access'
    });
    // 调用接口刷新token
    login(datas, { headers: { noLoading: true } }).then((res: any) => {
      requestStore.isRefreshToken = false;
      if (res.data) {
        // 更新token
        requestStore.setToken(res.data.access_token, res.data.refresh_token, res.data.expires_in);
        // 重新建立实时消息连接
        connectSignalr(adminInfoStore.adminInfo.id, requestStore.token);
        // 再次添加定时刷新token任务
        addRefreshAccessTokenTask();
      }
    }).catch(() => {
      requestStore.isRefreshToken = false;
    });
  }
}

// 获取管理员登录信息
function getAdminInfoFn(callback?: any) {
  const adminInfoStore = useAdminInfoStore();
  const requestStore = useRequestStore();
  getAdminInfo().then((res: any) => {
    if (res.data.success) {
      let result = res.data.data;
      result.permissionCodes = result.permissions || [];
      const useMenu = useMenuStore();
      const useMenus = JSON.parse(JSON.stringify(data.menuData));
      useMenu.menu = checkMemuData(useMenus, res.data.data.permissions)
      adminInfoStore.adminInfo = res.data.data;
      if (window.$setGlobalState) {
        window.$setGlobalState({
          adminInfo: res.data.data
        });
      }
      if (typeof callback === 'function') {
        callback(true, res.data.data);
      }
    } else {
      if (typeof callback === 'function') {
        callback(false);
      }
    }
  }).catch(() => {
    if (typeof callback === 'function') {
      callback(false);
    }
  });
  // 建立实时消息连接
  connectSignalr(adminInfoStore.adminInfo.id, requestStore.token);
  addRefreshAccessTokenTask();
}

// 检查菜单权限，处理菜单数据
function checkMemuData(memu: any, codes: any) {
  return memu.filter((item: any) => {
    if (codes.indexOf(item.code) > -1 || (item.children && item.children.map((v: any) => v.code).indexOf(item.code) <= -1 && codes.every((value: any) => codes.includes(value)))) {
      if (item.children) {
        item.children = item.children.filter((child: any) => codes.indexOf(child.code) > -1);
      }
      if (item.children && !item.children.length && !item.path) {
        return false;
      }
      return true;
    }
    return false;
  });
}

// 发起退出登录请求
function sendLogoutRequest(token: any) {
  const adminInfoStore = useAdminInfoStore();
  let option = {
    headers: {
      // 'Authorization-Type': 'Negotiate',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  }
  let formData: any = {
    token: token,
    token_type_hint: "access_token",
    client_id: data.APP_AUTH_NAME,
    client_secret: 'secret',
    account_Id: adminInfoStore.adminInfo.id
  };
  formData = new URLSearchParams(formData);
  loginOut(formData, option).then(() => {
    router.push({ path: '/login' });
  }).catch(() => {
    router.push({ path: '/login' });
  });
}

// 退出登录(手动退出传true, 并执行路由跳转; 自动退出是由路由跳转触发该方法，不再执行路由跳转)
function logout(needRequest?: boolean) {
  const requestStore = useRequestStore();
  let token = requestStore.token;
  requestStore.setToken('', '', 0);
  requestStore.deleteRequestListener(); // 删除定时退出定时器
  clearTimeout(tokenUpdateTimer); // 退出登录后，销毁自动刷新token任务
  if (needRequest) {
    sendLogoutRequest(token);
  }
  // 销毁实时消息连接
  destroySignalr();
}

export default {
  // 获取管理员登录信息
  getAdminInfo: getAdminInfoFn,
  // 登录
  login(token: string, refreshToken: string, expiresIn: number, callback?: any) {
    const requestStore = useRequestStore();
    requestStore.setToken(token, refreshToken, expiresIn);
    getAdminInfoFn(callback);
  },
  // 从微应用来的退出登录
  logoutFromMicroApp() {
    logout(true);
  },
  // 退出登录(手动退出传true, 并执行路由跳转; 自动退出是由路由跳转触发该方法，不再执行路由跳转)
  logout,
}