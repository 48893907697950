import { defineComponent, ref, watch, onBeforeUnmount } from 'vue'
export default defineComponent({
    name: 'sv-virtual-scroll',
    props: {
        // 是否开启分页接口加载
        remoteSearch: {
            type: Boolean,
            default: false
        },
        // 开启分页后是否正在加载
        loading: {
            type: Boolean,
            default: false
        },
        // 开启分页后的分页信息
        pageInfo: {
            type: Object,
            default() {
                return {
                    page: 1,
                    pageCount: 1
                };
            }
        },
        // 方向,可选vertical / horizontal
        direction: {
            type: String,
            default: 'vertical'
        },
        data: {
            type: Array,
            deafault: function () {
                return [];
            }
        },
        itemSize: {
            type: Number || String,
            default: 32
        },
        keyField: {
            type: String,
            default: 'id'
        },
        sizeField: {
            type: String,
            default: 'size'
        },
    },
    emits: ['update', 'scroll-start', 'scroll-end', 'need-search'],
    setup(props: any, context: any): any {

        /***
         * 加载是否完成
         * 值根据pageInfo属性的分页信息自动更新.
         */
        // 加载完成状态
        let loaded = ref(false);
        // 获取加载完成状态
        let getStatusLoaded = function () {
            return loaded.value;
        }
        // 设置为加载完成
        let setStatusLoaded = function () {
            loaded.value = true;
        }
        // 重置为加载未完成
        let resetLoadStatus = function () {
            loaded.value = false;
        }
        // 监听接口搜索分页信息，判断是否加载完成
        watch(() => props.pageInfo, (newVal: any) => {
            if (newVal.page >= newVal.pageCount) {
                setStatusLoaded();
            } else {
                resetLoadStatus();
            }
        });

        /***
         * 添加加载样式dom
         * 当remoteSearch为true时，若滚动到底部时未完成加载，则添加加载样式；
         * 若已加载完成，则移除加载样式.
         */
        let loadingDom: any = null;
        let getLoadingDom = function () {
            if (loadingDom) {
                return loadingDom;
            }
            let div: any = document.createElement('div');
            div.className = props.direction === 'horizontal' ? 'sv-loading-box-horizontal' : 'sv-loading-box';
            div.innerHTML = `
            <svg class="sv-loading-svg" viewBox="0 0 50 50" width="60" height="50" xmlns="http://www.w3.org/2000/svg">
                <circle class="sv-loading-circle" cx="25" cy="25" r="20" fill="none"></circle>
            </svg>
            `;
            loadingDom = div;
            return div;
        }
        let scroller: any = ref<any>(null);
        let loadedHandler = function () {
            if (props.remoteSearch && scroller.value) {
                if (!loaded.value) {
                    if (!loadingDom || !scroller.value.$el.contains(loadingDom)) {
                        scroller.value.$el.appendChild(getLoadingDom());
                    }
                    if (!props.loading) {
                        handleNeedSearch();
                    }
                } else if (scroller.value.$el.contains(loadingDom)) {
                    scroller.value.$el.removeChild(loadingDom);
                    loadingDom = null;
                }
            }
        }
        onBeforeUnmount(() => {
            if (scroller.value && loadingDom && scroller.value.$el.contains(loadingDom)) {
                scroller.value.$el.removeChild(loadingDom);
            }
            loadingDom = null;
        });

        // 事件触发
        let handleUpdate = function (sIndex: number, eIndex: number) {
            context.emit('update', sIndex, eIndex);
        }
        let handleScrollStart = function () {
            context.emit('scroll-start');
        }
        let handleScrollEnd = function () {
            loadedHandler();
            context.emit('scroll-end');
        }
        let handleNeedSearch = function () { // 处理查询时props.pageInfo.page需要加1
            context.emit('need-search');
        }

        context.expose({ getStatusLoaded });

        return {
            loaded,
            getStatusLoaded,
            scroller,
            handleUpdate,
            handleScrollStart,
            handleScrollEnd
        };
    }
});