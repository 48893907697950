/***
 * v-role指令
 * @property {String | Object} directive.value 如果为string, 则判断为单条权限code；否则为对象则根据对象属性判断.
 * @property {String} directive.value.role 单条权限code,优先级低.
 * @property {Array} directive.value.roles 多条权限的code数组,优先级高. 只要登录用户有任一code指定的权限，就认为拥有权限.
 * @author Wangyu
 * 使用示例:
 *      <div v-role="'M_Code'"> Dom Text </div>
 *      <div v-role="{ role: 'M_Code' }"> Dom Text </div>
 *      <div v-role="{ roles: ['M_CodeA', 'M_CodeB'] }"> Dom Text </div>
 */

import { useAdminInfoStore } from '@/stores/adminInfo'
import $ from 'jquery'

function updateDom(el: any, binding: any) {
  const store = useAdminInfoStore();
  let permissionCodes = store.getPermissionCodes();
  let obj = binding.value;
  // 没有role或者roles为空数组，表示不做权限限制
  if (!permissionCodes.length || !obj) {
    return;
  }
  if (typeof obj === 'string') {
    getRule(permissionCodes, obj, el);
  } else if (obj.roles && obj.roles.length) {
    getRuleByRoles(permissionCodes, obj.roles, el);
  } else if (obj.role) {
    getRule(permissionCodes, obj.role, el);
  }
}

function getRule(codes: any, role: any, el: any) {
  let flag = codes.findIndex((item: any) => {
    return role === item;
  });
  if (flag === -1 || !codes.length) {
    $(el).remove();
    setTimeout(() => {
      if (el) {
        $(el).remove();
      }
    }, 0);
  }
}

function getRuleByRoles(codes: any, roles: any, el: any) {
  let noCode = true;
  if (!codes.length) {
    noCode = true;
  } else {
    roles.forEach((role: any) => {
      let index = codes.findIndex((item: any) => {
        return role === item;
      });
      if (index > -1) {
        noCode = false;
      }
    })
  }
  if (noCode) {
    $(el).remove();
    setTimeout(() => {
      if (el) {
        $(el).remove();
      }
    }, 0);
  }
}

export default {
  // 当绑定元素插入到DOM中
  mounted: function (el: any, binding: any) {
    updateDom(el, binding);
  }
}