<template>
  <!--前面带原点的没有框的及默认的-->
  <span class="sv-tag" :class="{ 'sv-tag-border': border > 0 || borderRadius > 0 }" :style="{
    'height': height + 'px',
    'lineHeight': height + 'px',
    'color': txtColor ? txtColor : '',
    'font-size': fontSize + 'px',
    'border': border + 'px solid ' + color,
    'border-radius': borderRadius ? borderRadius + 'px' : border ? height / 2 + 'px' : 'unset',
    'background': background,
  }">
    <label v-if="type.indexOf('dotted') > -1" class="sv-tag-label" :style="{ background: color }"></label>
    <slot>
      <span>{{ txt }}</span>
    </slot>
  </span>
</template>
<script lang="ts">
import { defineComponent, computed, ref } from 'vue'

export default defineComponent({
  name: 'sv-tag',
  props: {
    /*默认不需要设置 如果是‘dotted’ 前面这有原点，需要其他属性可自行设置*/
    type: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    /*标签描述文字的大小*/
    fontSize: {
      type: Number,
      default: 16
    },
    /*标签的高度*/
    height: {
      type: Number,
      default: 20
    },
    /*标签的描述*/
    txt: {
      type: String,
      default: ''
    },
    /*没有设置有文字颜色时，会默认是有color的颜色*/
    txtColor: {
      type: String,
      default: ''
    },
    /*表框线的宽度，默认是0，没有边框线*/
    border: {
      type: Number,
      default: 0,
    },
    /*元素的圓角 注：1.如果有圆角或者有边框线就回默认有左右的内边距 2.如果没有圆角但是有边框线属性时，圆角默认成高度的一半即50%*/
    borderRadius: {
      type: [Number, String],
      default: 0,
    },
    background: {
      type: String,
      default: ''
    }
  },
})
</script>
<style scoped lang="scss">
.sv-tag {
  display: inline-flex;
  align-items: center;

  &.sv-tag-border {
    padding: 0 16px;
  }

  .sv-tag-label {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 4px;
  }
}
</style>
