import request from '@/utils/request'

const LOGIN_ROOT = '/auth';
const AUTH_ROOT = '/auth/api/v1';

/**
 * Login
 */
export function login(data: any, options?: any) {
  return request.post(LOGIN_ROOT + '/connect/token', data, options);
}

export function getAdminInfo(options?: any) {
  return request.get(AUTH_ROOT + '/Accounts/GetLoginAccountInfo?source=Ultimo', options);
}

export function loginOut(data?: any, options?: any) {
  return request.post(LOGIN_ROOT + '/connect/revocation', data, options);
}

export function getRsaPucKey(options?: any) {
  return request.get(AUTH_ROOT + '/Encryption/GetRSAPublicKey', options);
}