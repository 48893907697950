// 地区时间格式
const times = {
  china: {
    typeDate:'yyyy-MM-dd',
    typeTime:'yyyy-MM-dd HH:mm:ss',
    typeDay:'MM-dd',
    typeDateHM:'yyyy-MM-dd HH:mm',

    typeDateStr:'YYYY-MM-DD',
    typeTimeStr:'YYYY-MM-DD HH:mm:ss',
    typeDayStr:'MM-DD',
    typeDateHMStr:'YYYY-MM-DD HH:mm',
  },
  singapore: {
    typeDate:'dd-MM-yyyy',
    typeTime:'dd-MM-yyyy HH:mm:ss',
    typeDay:'dd-MM',
    typeDateHM:'dd-MM-yyyy HH:mm',

    typeDateStr:'DD-MM-YYYY',
    typeTimeStr:'DD-MM-YYYY HH:mm:ss',
    typeDayStr:'DD-MM',
    typeDateHMStr:'DD-MM-YYYY HH:mm',
  }
};

export default times;
