export default {
    arrowDown: `
        <path fill="currentColor"
            d="M831.872 340.864 512 652.672 192.128 340.864a30.592 30.592 0 0 0-42.752 0 29.12 29.12 0 0 0 0 41.6L489.664 714.24a32 32 0 0 0 44.672 0l340.288-331.712a29.12 29.12 0 0 0 0-41.728 30.592 30.592 0 0 0-42.752 0z">
        </path>
    `,
    circleClose: `
        <path fill="currentColor"
            d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z">
        </path>
        <path fill="currentColor"
            d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896">
        </path>
    `,
    close: `
        <path fill="currentColor"
            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
        </path>
    `,
    passwordHide: `
        <path fill="currentColor"
            d="M512 766.805333a406.442667 406.442667 0 0 1-91.904-10.496l46.933333-81.066666a171.306667 171.306667 0 0 0 165.589334-286.890667l55.04-95.146667a405.333333 405.333333 0 0 1 201.258666 216.746667 405.589333 405.589333 0 0 1-146.432 184.874667A401.962667 401.962667 0 0 1 512 766.805333z m-173.866667-39.210666a404.949333 404.949333 0 0 1-203.050666-217.642667 405.333333 405.333333 0 0 1 470.826666-246.016l-46.933333 81.066667a171.306667 171.306667 0 0 0-166.144 287.829333l-54.656 94.848zM512 612.736c-2.901333 0-5.845333 0-8.746667-0.384l93.098667-161.28A102.869333 102.869333 0 0 1 512 612.736z m-83.114667-42.197333A102.869333 102.869333 0 0 1 512 407.168a105.045333 105.045333 0 0 1 10.88 0.554667l-93.952 162.816z">
        </path>
    `,
    passwordVisible: `
        <path fill="currentColor"
            d="M512 255.018667A405.333333 405.333333 0 0 0 135.125333 512a404.864 404.864 0 0 0 753.792 0A405.333333 405.333333 0 0 0 512 255.018667z m0 428.288A171.306667 171.306667 0 1 1 683.306667 512 171.392 171.392 0 0 1 512 683.306667z m0-274.090667A102.784 102.784 0 1 0 614.784 512 102.656 102.656 0 0 0 512 409.216z">
        </path>
    `,
    reset: `
        <path fill="currentColor" stroke="currentColor"
            d="M784.512 230.272v-50.56a32 32 0 1 1 64 0v149.056a32 32 0 0 1-32 32H667.52a32 32 0 1 1 0-64h92.992A320 320 0 1 0 524.8 833.152a320 320 0 0 0 320-320h64a384 384 0 0 1-384 384 384 384 0 0 1-384-384 384 384 0 0 1 643.712-282.88z">
        </path>
    `,
    tableSet: `
        <path fill="currentColor" stroke="currentColor"
            d="M128 721.194667h439.424v-88.149334H128zM708.608 633.045333h188.245333V721.066667h-188.245333V810.666667h-85.333333v-267.392h85.333333zM457.386667 421.930667h439.466666v-88.149334h-439.466666zM315.733333 248.576h85.333334V516.736h-85.333334v-94.890667H128v-88.149333h187.733333zM128.170667 138.752h278.314666v-88.106667H128.170667zM548.138667 138.752v93.568h-85.333334V-42.666667h85.333334v93.312h348.714666v88.106667z">
        </path>
    `,
    warning: `
        <path fill="currentColor"
            d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896m0 832a384 384 0 0 0 0-768 384 384 0 0 0 0 768m48-176a48 48 0 1 1-96 0 48 48 0 0 1 96 0m-48-464a32 32 0 0 1 32 32v288a32 32 0 0 1-64 0V288a32 32 0 0 1 32-32">
        </path>
    `
}