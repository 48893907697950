import request from '@/utils/request'
import event from '@/utils/event';
import * as signalR from '@microsoft/signalr';
const API_ROOT = process.env.VUE_APP_MESSAGECENTER_API;
const SIGNALR_ROOT = process.env.VUE_APP_SIGNALR_API;

/***
 * 消息API
 */

export function getNotificationRecord(data: any, options?: any) {
  return request.post(API_ROOT + '/NotificationRecord/Paginate', data, options);
}

//消息数量
export function getRecordCount(data: any, options?: any) {
  return request.post(API_ROOT + '/NotificationRecord/RecordCount', data, options);
}

//消息已读
export function gNotificationRead(data: any, options?: any) {
  return request.post(API_ROOT + '/NotificationRecord/Read', data, options);
}

//手动发消息用的
export function sendNotificationRecord(data: any, options?: any) {
  return request.post(API_ROOT + '/NotificationRecord/TestSendNotification', data, options);
}

/***
 * 实时消息推送
 */
let msgConnection: any;
let msgTimer: any = null;

function getConnection() {

  // 销毁Signalr链接
  let destroy = function () {
    if (msgConnection) {
      clearTimeout(msgTimer);
      msgConnection.stop();
      msgConnection = '';
    }
  }

  // 建立连接
  let connect = function (options: any) {
    destroy();

    msgConnection = new signalR.HubConnectionBuilder().withUrl(SIGNALR_ROOT + "/messageClient?ServerToken=" + options.userId + "&AppKey=Ultimo.Essential", {
      accessTokenFactory: () => options.token.replace('Bearer ', '')
    }).build();

    msgConnection.on('ReceiveMessage', function (message: any) {
      options.receiveMessage(message);
    });

    msgConnection.start().catch(function () {
      console.log('连接失败');
    });

    async function start() {
      clearTimeout(msgTimer);
      try {
        // console.log('尝试重连...');
        await msgConnection.start();
        // console.log('已重连');
      } catch (err) {
        // console.log('重连失败');
        msgTimer = setTimeout(() => start(), 2000);
      }
    }

    msgConnection.onclose(async () => {
      if (msgConnection) {
        await start();
      }
    });

  };

  return {
    connect,
    destroy
  }
}

// 链接signalr
export function connectSignalr(adminId: string, token: string | null) {
  let options: any = {
    userId: adminId,
    token: token,
    // 实时获取事件推送的消息
    receiveMessage: function (message: any) {
      console.log("收到了一条通知", message)
      if (message.locationNames.length > 0) {
        message.locationName = message.locationNames[0].replace(/\/\$/g, '');
      } else {
        message.locationName = '';
      }
      event.emit('realMessageArrived', message);
    },
  };
  let connection = getConnection();
  connection.connect(options);
}

// 销毁signalr
export function destroySignalr() {
  let connection = getConnection();
  connection.destroy();
}
