<template>
  <div class="sv-paging" :style="{ 'text-align': modelValue.align || 'right' }">
    <span class="sv-paging-count">
      Count:
      <img v-if="loading" class="sv-paging-load-img" src="./img/loding.gif" />
      <span v-else>{{ modelValue.itemCount }}</span>
    </span>
    <!-- 每页显示条数 -->
    <span>Per Page</span>
    <el-select v-if="showPerPage" v-model="modelValue.perPage" popper-class="sv-page-select-popper" class="sv-page-select" placement="top" @change="selectPerPage(modelValue)" placeholder="Select">
      <el-option v-for="value in perPageList" :value="value" :key="value" :label="value">
        <div>{{ value }}</div>
      </el-option>
    </el-select>
    <div>
      <a @click.prevent="goToFirstPage"><span class="sv-paging-icon"
          :class="modelValue.page === 1 ? 'sv-paging-icon-first-no' : 'sv-paging-icon-first'"></span></a>
      <p v-show="showMsgType === 'first'" class="sv-paging-msg">{{ btnText.first }}</p>
    </div>
    <div>
      <a @click.prevent="goToPrevPage"><span class="sv-paging-icon"
          :class="modelValue.page < 2 ? 'sv-paging-icon-pre-no' : 'sv-paging-icon-pre'"></span></a>
      <p v-show="showMsgType === 'pre'" class="sv-paging-msg">{{ btnText.pre }}</p>
    </div>
    <span>
      <span v-if="loading"> <img class="sv-paging-load-img" src="./img/loding.gif" /></span>
      <span v-else style="color: #333" >{{ modelValue.page }} / {{ modelValue.pageCount || 1 }}</span>
    </span>
    <div>
      <a @click.prevent="goToNextPage">
        <span class="sv-paging-icon" :class="modelValue.page < modelValue.pageCount ? 'sv-paging-icon-next' : 'sv-paging-icon-next-no'"></span>
      </a>
      <p v-show="showMsgType === 'next'" class="sv-paging-msg">{{ btnText.next }}</p>
    </div>
    <div>
      <a @click.prevent="goToFinallyPage">
        <span class="sv-paging-icon" :class="modelValue.page <  modelValue.pageCount ? 'sv-paging-icon-last' : 'sv-paging-icon-last-no'"></span>
      </a>
      <p v-show="showMsgType === 'last'" class="sv-paging-msg">{{ btnText.last }}</p>
    </div>
    <span>Jump to</span>
    <div style="vertical-align: top">
      <input 
        type="number" 
        v-model="pageValue" 
        title="" 
        @keydown="handlePageKeyDown"
        @input="handlePageInput" 
        @keyup.enter="goToPage" 
        min="1"
        :max="modelValue.pageCount" 
      />
    </div>
    <div>
      <a @click.prevent="goToPage" class="sv-paging-go">{{ btnText.gobtn }}</a>
      <p v-show="showMsgType === 'goTo'" class="sv-paging-msg">{{ btnText.go }}</p>
    </div>
  </div>
</template>

<script lang="ts" src="./sv-paging">
</script>

<style lang="scss" src="./sv-paging.scss" scoped>
</style>
