import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11ab08b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "sv-image-preview-box" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sv_svg_icon = _resolveComponent("sv-svg-icon")!

  return (_openBlock(), _createElementBlock("span", {
    class: "sv-imgage-box",
    style: _normalizeStyle({ 'width': _ctx.width, 'height': _ctx.height })
  }, [
    (_ctx.base64Data || _ctx.url)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          ref: "imageRef",
          class: _normalizeClass(_ctx.className),
          crossorigin: "Anonymous",
          src: _ctx.base64Data || _ctx.url,
          style: _normalizeStyle({ 'object-fit': _ctx.objectFit }),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShowBigImage && _ctx.handleShowBigImage(...args))),
          onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleImageError && _ctx.handleImageError(...args))),
          onLoad: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleImageLoaded && _ctx.handleImageLoaded(...args)))
        }, null, 46, _hoisted_1))
      : _renderSlot(_ctx.$slots, "default", { key: 1 }, () => [
          _createElementVNode("img", {
            class: "user-photo",
            src: require('./img/default-user.png'),
            style: _normalizeStyle({'object-fit': _ctx.objectFit }),
            alt: ""
          }, null, 12, _hoisted_2)
        ], true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.previewModal)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "sv-image-mask",
            onScroll: _cache[4] || (_cache[4] = (e) => e.preventDefault())
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.previewUrl,
                alt: ""
              }, null, 8, _hoisted_4)
            ]),
            _createVNode(_component_sv_svg_icon, {
              class: "sv-image-preview-close",
              color: "#fff",
              "font-weight": "bold",
              "font-size": "50px",
              type: "circleClose",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.previewModal = false))
            })
          ], 32))
        : _createCommentVNode("", true)
    ]))
  ], 4))
}