import { defineComponent, computed, ref, onMounted, watch, nextTick } from 'vue'
import svTag from '@/components/sv-tag'

export default defineComponent({
    name: 'SV-Ellipsis',
    components:{svTag},
    props: {
        // el-tooltip的Attributes配置
        objectOfAttrs: {
            type: Object,
            default: function () {
                return {};
            }
        },
        content: {
            type: [String],
            default: ""
        },
        width: {
            type: String,
            default: '100%',
        },
        lineHeight: {
            type: String,
            default: '1.5',
        },
        //是否开启移入提示
        tooltip:{
            type: Boolean,
            default: false
        },
        // 是否多行文本
        isMultipleLine: {
            type: Boolean,
            default: false
        },
        // 最多显示行数，要配合isMultipleLine一起只用
        lineClamp: {
            type: Number,
            default: 2
        },
        placement: {
            type: String,
            default: 'bottom'
        },
        // 是否需要展开显示所有文本,要配合isMultipleLine一起只用
        showAllText: {
            type: Boolean,
            default: false
        },
        // 单行输入的文本对齐方式
        textAlign:{
            type:String,
            default:''
        },
        // 是否需要显示更多标签
        moreTag: {
            type: Boolean,
            default: false
        },
        // 是否需要显示更多标签
        moreTagBoxWidth: {
            type: Number,
            default: 200
        },
        contentData:{
            type: Array,
            default: function () {
                return [];
            }
        },
        contentDataLabel:{
            type:String,
            default:''
        },
        trigger:{
            type:String,
            default:'hover'
        },
    },
    setup(props: any) {
        let isDisabled = ref(false);
        let expand = ref(false);

        // 计算文本的盒子的宽度
        const initWidth = computed(() => {
            return props.width === '100%' ? props.width : props.width.indexOf('px') > -1 ? props.width : props.width + 'px';
        });
        const initLineClamp = computed(() => {
            return expand.value ? 'unset': props.lineClamp;
        });


        // 判断是否出现省略号,判断是否打开移入提示
        let showCode = function (e: any) {
            if (e.target.clientWidth < e.target.scrollWidth) {
                isDisabled.value = false;
            } else {
                isDisabled.value = true;
            }
        };

        let showLineCode = function (e: any) {
            if (e.target.scrollHeight > e.target.clientHeight) {
                isDisabled.value = false;
            } else {
                isDisabled.value = true;
            }
        };

        let moreTagCount = ref(0);
        let tagContainerRef = ref(null);
        const checkOverflowAndUpdateCount = () => {
            moreTagCount.value = 0;
            nextTick(() => {
              if (tagContainerRef.value) {
                console.log(1);
                let dom:any = tagContainerRef.value;
                let boxWidth = dom.clientWidth;
                let children = dom.children;
                let widthTotal = 0;
                let isOverflowing = Array.from(children).some((element:any, index) => {
                  widthTotal += element.clientWidth;
                  if (widthTotal > (boxWidth - 40)) {
                    console.log('over');
                    moreTagCount.value = props.contentData.length - index;
                    return true;
                  }
                  return false;
                });
                if (!isOverflowing) {
                    console.log('no over');
                  moreTagCount.value = 0; // Reset if not overflowing
                }
              }
            });
        };

        onMounted(() => {
            if (props.moreTag) {
                checkOverflowAndUpdateCount()
            }
        });

        watch(() => props.contentData, () => {
            checkOverflowAndUpdateCount();
        }, { 
            deep: true, 
            immediate: true 
        });

        return {
            isDisabled,
            expand,
            initWidth,
            initLineClamp,
            showCode,
            showLineCode,
            moreTagCount,
            checkOverflowAndUpdateCount,
            tagContainerRef
        }
    },
})