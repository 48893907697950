import { defineComponent, reactive, onMounted, ref } from 'vue'
import SvFont from '../sv-font-icon/index'
import phoneCode from './phoneCode'
export default defineComponent({
name: 'svSearchBar',
components: {
SvFont
},
props: {
searchData: {
  type: Object,
  default() {
    return {
      searchInfo: []
    }
  }
},
transparentBg: {
  type: Boolean,
  default: false
},
// 是否显示全局加载层
showQueryGlobalLoading: {
  type: Boolean,
  default: false,
},
// 是否需要提示
isNeedTip: {
  type: Boolean,
  default: false
}
},
setup(props: any, context: any) {
let search = function () {
  reorganizationDate()
  context.emit('getSearch', searchParams.value)
}
let phoneCodeSelect = reactive(phoneCode)
let searchParams:any= ref({});
let reset = function () {
  props.searchData.searchInfo.forEach((element: any) => {
    if (element.type == 'input') {
      element.keyword = ''
    } else if (element.type == "select-multiple") {
      element.keyword = []
    } else if (element.type == "select") {
      element.keyword = ""
    } else if (element.type == "date-time-group" || element.type == 'select-input') {
      element.content.forEach((item: any) => {
        item.keyword = null
      })
    } else if (element.type == 'cascader') {
      element.keyword = []
    }
  });
  searchParams.value = {}
  context.emit('getSearch', searchParams.value)
}
/*重组数据*/
let reorganizationDate = function () {
  let data = JSON.parse(JSON.stringify(props.searchData.searchInfo));
  searchParams.value = {};
  data.forEach((item: any) => {
    let type = item.type;
    if (type == 'date-time-group') {
      item.content.forEach((value: any) => {
        if (value.keyword && value.keyword != '') {
          searchParams.value[value.filed] = new Date(value.keyword).getTime();
        }
      });
    } else if (type == 'select-input') {
      item.content.forEach((value: any) => {
        if (value.keyword != '') {
            searchParams.value[value.filed] = value.keyword;
        }
      });
    } else if (type == 'select' && item.keyword !== '') {
        searchParams.value[item.filed] = item.keyword
    }else if ( type == 'cascader'){
      if(item.keyword && item.keyword.length){
          searchParams.value[item.filed] =  [item.keyword[1]]
      }  
    }
    else if (item.keyword !== '' && item.keyword.length > 0) {
        searchParams.value[item.filed] = item.keyword
    }
  })
};
let changeIsShrink = function () {
  context.emit('changeIsShrink')
}
onMounted(() => {
  props.searchData.searchInfo.forEach((item: any) => {
    if (item.queryFun) {
      let searchParams = item.type == 'cascader' ? null : {
        sortName: 'name',
        sortType: 0,
        enabledPaging: false,
      }
      if (item.searchParams) {
        searchParams = item.searchParams
      }
      if(item.filed == 'locationIds'){
        item.queryFun().then((res: any) => {
          item.selectData = res.data.data.filter((it:any)=>{return it.children && it.children.length}).map((v:any)=>{return v.children}).flat()
        })
      }else{
      item.queryFun(searchParams, {
        headers: {
          noLoading: !props.showQueryGlobalLoading
        }
      }).then((res: any) => {
        if (item.title !== 'Rank') {
          if (item.type == 'cascader') {
            item.options = res.data.data
            // console.log(item.options, res.data.data)
          } else {
            item.selectData = res.data.data.items
          }
        } else {
          item.selectData = [res.data.data]
        }
      })
    }
  }
  })
})
return {
  search,
  reset,
  changeIsShrink,
  phoneCodeSelect,
  searchParams
}
},

})