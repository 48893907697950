import { useAdminInfoStore } from '@/stores/adminInfo'
/***
  * @method hasPermissionByCodes
  * @description 根据codes判断是否有权限.
  * @param {String | Array} codes 权限code.可传数组或字符串，当为数组时只要有codes中的一个code对应的权限，则返回true.
  * @return {Boolean} 是否有对应的权限.
  */
export function hasPermissionByCodes(codes: any) {
    let adminStore = useAdminInfoStore();
    let permissionCodes = adminStore.adminInfo.permissionCodes || [];
    if (typeof codes === 'string') { // 参数兼容
      codes = [codes];
    }
    for (let i = 0; i < codes.length; i++) {
      if (permissionCodes.indexOf(codes[i]) > -1) {
        return true;
      }
    }
    return false;
}