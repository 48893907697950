import { openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-184e90d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["sv-svg-icon", { 'sv-svg-icon-normal': _ctx.fontWeight === 'normal', 'sv-svg-icon-bold': _ctx.fontWeight === 'bold' }]),
    style: _normalizeStyle({ 'color': _ctx.color, 'font-size': _ctx.fontSize })
  }, [
    (_openBlock(), _createElementBlock("svg", {
      style: {"width":"16px"},
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 1024 1024",
      innerHTML: _ctx.svgData[_ctx.type]
    }, null, 8, _hoisted_1))
  ], 6))
}