/***
 * 微应用加载状态
 * @prop {boolean} loading 是否在加载中.
 * @prop {boolean} showText 是否显示加载文本.
 * @prop {string} text 加载文本.
 * @prop {boolean} loadFailed 是否加载失败.
 * @prop {string} failedText 加载失败文本.
 */

import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useMicroAppLoadStore = defineStore('microAppLoad', () => {

  const loading = ref(false);
  const showText = ref(true);
  const text = ref('Loading...');
  const loadFailed = ref(false);
  const failedText = ref('Failed to load');

  return { 
    loading, 
    showText, 
    text, 
    loadFailed, 
    failedText,
  };
});

// store.$patch({
//   loading: loadingValue,
// })