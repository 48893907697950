<template>
    <div class="sv-ellipsis-wrapper">
        <template v-if="moreTag">
            <div ref="tagContainerRef" v-if="contentData && contentData.length > 0" class="sv-ellipsis-tag-container">
                <template v-for="(item,index) in contentData" :key="index">
                    <span class="sv-ellipsis-tag" v-show="index < (contentData.length - moreTagCount)">
                        <slot name="tagContent" :item="item" :index="index">
                            {{contentDataLabel ? item[contentDataLabel] : item}}
                        </slot>
                    </span>
                </template>
                <el-popover  v-if="moreTagCount > 0" :placement="placement" :width="moreTagBoxWidth" :trigger="trigger" popper-class="sv-popper sv-popper-arrow">
                    <template #reference>
                        <span v-if="moreTagCount > 0" class="sv-more-tag">+ {{ moreTagCount }}</span>
                        <!-- <slot name="tagContent" :count="moreTagCount">
                            
                        </slot> -->
                    </template>
                    <div class="sv-ellipsis-tag-popover">
                        <template v-for="(item,index) in contentData" :key="index">
                            <template v-if="index > contentData.length - moreTagCount - 1">
                                <slot name="moreTagContent" :item="item" :index="index">
                                    <div class="sv-ellipsis-tag-popover_li" >
                                        {{ contentDataLabel ? item[contentDataLabel] : item }}
                                    </div>
                                </slot>
                            </template>
                        </template>
                    </div>
                </el-popover>
                <!-- <sv-tag v-if="moreTagCount > 0">More {{ moreTagCount }}</sv-tag> -->
                <template v-else></template>
            </div>
        </template>
        <template v-else>
            <el-tooltip v-bind="objectOfAttrs" :content="content" :disabled="isDisabled" :placement="placement">
                <!-- 单行文本省略移入提示 -->
                <template v-if="!isMultipleLine">
                    <div class="sv-ellipsis" v-on:mouseenter="showCode($event)"
                        :style="{ 'width': initWidth, 'lineHeight': lineHeight ,'text-align':textAlign}">
                        <slot>
                            <span class="sv-ellipsis-txt">{{ content || content === '0' ? content : '-' }}</span>
                        </slot>
                    </div>
                </template>

                <!-- 多行文本省略移入提示 -->
                <template v-else>
                    <div class="sv-ellipsis-multiple-line" v-on:mouseenter="showLineCode($event)"
                        :style="{ '-webkit-line-clamp': initLineClamp, 'lineHeight': lineHeight }">
                        <slot>
                            <span class="sv-ellipsis-txt">{{ content || content === '0' ? content : '-' }}</span>
                        </slot>
                    </div>
                    
                </template>
                <template #content>
                    <slot name="tooltipText">
                    
                    </slot>         
                </template>
            </el-tooltip>
            <!-- 是否可以展开看所有文本 -->
            <template v-if="showAllText">
                <span class="sv-ellipsis-manualView-btn">
                    <slot name="btn" :expand="expand">
                        <i class="arrow ivu-icon ivu-icon-ios-arrow-down" @click="expand = !expand"></i>
                    </slot>
                </span>
            </template>
        </template>
    </div>
</template>
<script lang="ts" src="./sv-ellipsis.ts"></script>
<style lang="scss" scoped>
.sv-ellipsis-wrapper {
    line-height: 1;

    .sv-ellipsis {
        position: relative;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;

        .sv-ellipsis-txt {
            padding-right: 2px;
        }
    }

    .sv-ellipsis-multiple-line {
        display: -webkit-box;
        white-space: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;

        .sv-ellipsis-txt {
            padding-right: 2px;
        }
    }
    .sv-ellipsis-tag-container{
        max-height: 300px;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis;
        .sv-ellipsis-tag{
            display: inline-flex;
            min-height: 24px;
            align-items: center;
            padding-right: 2px;
        }
        .sv-more-tag{
            display: inline-flex;
            height: 24px;
            align-items: center;
            padding: 0 8px;
            margin-left: 4px;
            color: var(--main-color);
            border-radius: 12px;
            @include main-bg-color-rgba(0.1);
            cursor: pointer;
        }
    }
}
.sv-ellipsis-tag-popover{
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    word-break: break-word;

    .sv-ellipsis-tag-popover_li{
        text-align: left;
        padding-left: 8px;
        border-top: 1px dashed #dadee1;
        height: 40px;
        line-height: 40px;
        &:first-of-type{
            border-top: 1px dashed transparent;
        }
    }
}
</style>
