const phoneCodes = [
  {
    "id": 2,
    "chineseName": "°¢¸»º¹",
    "englishName": "Afghanistan",
    "code": "+93"
  },
  {
    "id": 3,
    "chineseName": "°¢¶û°ÍÄáÑÇ",
    "englishName": "Albania",
    "code": "+355"
  },
  {
    "id": 4,
    "chineseName": "°¢¶û¼°ÀûÑÇ",
    "englishName": "Algeria",
    "code": "+213"
  },
  {
    "id": 5,
    "chineseName": "°²µÀ¶û¹²ºÍ¹ú",
    "englishName": "Andorra",
    "code": "+376"
  },
  {
    "id": 1,
    "chineseName": "°²¸çÀ­",
    "englishName": "Angola",
    "code": "+244"
  },
  {
    "id": 6,
    "chineseName": "°²¹çÀ­µº",
    "englishName": "Anguilla",
    "code": "+1264"
  },
  {
    "id": 7,
    "chineseName": "°²Ìá¹ÏºÍ°Í²¼´ï",
    "englishName": "Antigua and Barbuda",
    "code": "+1268"
  },
  {
    "id": 8,
    "chineseName": "°¢¸ùÍ¢",
    "englishName": "Argentina",
    "code": "+54"
  },
  {
    "id": 9,
    "chineseName": "ÑÇÃÀÄáÑÇ",
    "englishName": "Armenia",
    "code": "+374"
  },
  {
    "id": 10,
    "chineseName": "°¢É­ËÉ",
    "englishName": "Ascension",
    "code": "+247"
  },
  {
    "id": 11,
    "chineseName": "°Ä´óÀûÑÇ",
    "englishName": "Australia",
    "code": "+61"
  },
  {
    "id": 12,
    "chineseName": "°ÂµØÀû",
    "englishName": "Austria",
    "code": "+43"
  },
  {
    "id": 13,
    "chineseName": "°¢Èû°Ý½®",
    "englishName": "Azerbaijan",
    "code": "+994"
  },
  {
    "id": 14,
    "chineseName": "°Í¹þÂí",
    "englishName": "Bahamas",
    "code": "+1242"
  },
  {
    "id": 15,
    "chineseName": "°ÍÁÖ",
    "englishName": "Bahrain",
    "code": "+973"
  },
  {
    "id": 16,
    "chineseName": "ÃÏ¼ÓÀ­¹ú",
    "englishName": "Bangladesh",
    "code": "+880"
  },
  {
    "id": 17,
    "chineseName": "°Í°Í¶àË¹",
    "englishName": "Barbados",
    "code": "+1246"
  },
  {
    "id": 18,
    "chineseName": "°×¶íÂÞË¹",
    "englishName": "Belarus",
    "code": "+375"
  },
  {
    "id": 19,
    "chineseName": "±ÈÀûÊ±",
    "englishName": "Belgium",
    "code": "+32"
  },
  {
    "id": 20,
    "chineseName": "²®Àû×È",
    "englishName": "Belize",
    "code": "+501"
  },
  {
    "id": 21,
    "chineseName": "±´Äþ",
    "englishName": "Benin",
    "code": "+229"
  },
  {
    "id": 22,
    "chineseName": "°ÙÄ½´óÈºµº",
    "englishName": "Bermuda Is.",
    "code": "+1441"
  },
  {
    "id": 23,
    "chineseName": "²£ÀûÎ¬ÑÇ",
    "englishName": "Bolivia",
    "code": "+591"
  },
  {
    "id": 24,
    "chineseName": "²©´ÄÍßÄÉ",
    "englishName": "Botswana",
    "code": "+267"
  },
  {
    "id": 25,
    "chineseName": "°ÍÎ÷",
    "englishName": "Brazil",
    "code": "+55"
  },
  {
    "id": 26,
    "chineseName": "ÎÄÀ³",
    "englishName": "Brunei",
    "code": "+673"
  },
  {
    "id": 27,
    "chineseName": "±£¼ÓÀûÑÇ",
    "englishName": "Bulgaria",
    "code": "+359"
  },
  {
    "id": 28,
    "chineseName": "²¼»ùÄÉ·¨Ë÷",
    "englishName": "Burkina-faso",
    "code": "+226"
  },
  {
    "id": 29,
    "chineseName": "Ãåµé",
    "englishName": "Burma",
    "code": "+95"
  },
  {
    "id": 30,
    "chineseName": "²¼Â¡µÏ",
    "englishName": "Burundi",
    "code": "+257"
  },
  {
    "id": 31,
    "chineseName": "¿¦ÂóÂ¡",
    "englishName": "Cameroon",
    "code": "+237"
  },
  {
    "id": 32,
    "chineseName": "¼ÓÄÃ´ó",
    "englishName": "Canada",
    "code": "+1"
  },
  {
    "id": 33,
    "chineseName": "¿ªÂüÈºµº",
    "englishName": "Cayman Is.",
    "code": "+1345"
  },
  {
    "id": 34,
    "chineseName": "ÖÐ·Ç¹²ºÍ¹ú",
    "englishName": "Central African Republic",
    "code": "+236"
  },
  {
    "id": 35,
    "chineseName": "Õ§µÃ",
    "englishName": "Chad",
    "code": "+235"
  },
  {
    "id": 36,
    "chineseName": "ÖÇÀû",
    "englishName": "Chile",
    "code": "+56"
  },
  {
    "id": 37,
    "chineseName": "ÖÐ¹ú",
    "englishName": "China",
    "code": "+86"
  },
  {
    "id": 38,
    "chineseName": "¸çÂ×±ÈÑÇ",
    "englishName": "Colombia",
    "code": "+57"
  },
  {
    "id": 39,
    "chineseName": "¸Õ¹û",
    "englishName": "Congo",
    "code": "+242"
  },
  {
    "id": 40,
    "chineseName": "¿â¿ËÈºµº",
    "englishName": "Cook Is.",
    "code": "+682"
  },
  {
    "id": 41,
    "chineseName": "¸çË¹´ïÀè¼Ó",
    "englishName": "Costa Rica",
    "code": "+506"
  },
  {
    "id": 42,
    "chineseName": "¹Å°Í",
    "englishName": "Cuba",
    "code": "+53"
  },
  {
    "id": 43,
    "chineseName": "ÈûÆÖÂ·Ë¹",
    "englishName": "Cyprus",
    "code": "+357"
  },
  {
    "id": 44,
    "chineseName": "½Ý¿Ë",
    "englishName": "Czech Republic",
    "code": "+420"
  },
  {
    "id": 45,
    "chineseName": "µ¤Âó",
    "englishName": "Denmark",
    "code": "+45"
  },
  {
    "id": 46,
    "chineseName": "¼ª²¼Ìá",
    "englishName": "Djibouti",
    "code": "+253"
  },
  {
    "id": 47,
    "chineseName": "¶àÃ×Äá¼Ó¹²ºÍ¹ú",
    "englishName": "Dominica Rep.",
    "code": "+1890"
  },
  {
    "id": 48,
    "chineseName": "¶ò¹Ï¶à¶û",
    "englishName": "Ecuador",
    "code": "+593"
  },
  {
    "id": 49,
    "chineseName": "°£¼°",
    "englishName": "Egypt",
    "code": "+20"
  },
  {
    "id": 50,
    "chineseName": "Èø¶ûÍß¶à",
    "englishName": "EI Salvador",
    "code": "+503"
  },
  {
    "id": 51,
    "chineseName": "°®É³ÄáÑÇ",
    "englishName": "Estonia",
    "code": "+372"
  },
  {
    "id": 52,
    "chineseName": "°£Èû¶í±ÈÑÇ",
    "englishName": "Ethiopia",
    "code": "+251"
  },
  {
    "id": 53,
    "chineseName": "ì³¼Ã",
    "englishName": "Fiji",
    "code": "+679"
  },
  {
    "id": 54,
    "chineseName": "·ÒÀ¼",
    "englishName": "Finland",
    "code": "+358"
  },
  {
    "id": 55,
    "chineseName": "·¨¹ú",
    "englishName": "France",
    "code": "+33"
  },
  {
    "id": 56,
    "chineseName": "·¨Êô¹çÑÇÄÇ",
    "englishName": "French Guiana",
    "code": "+594"
  },
  {
    "id": 136,
    "chineseName": "·¨Êô²£ÀûÄáÎ÷ÑÇ",
    "englishName": "French Polynesia",
    "code": "+689"
  },
  {
    "id": 57,
    "chineseName": "¼ÓÅî",
    "englishName": "Gabon",
    "code": "+241"
  },
  {
    "id": 58,
    "chineseName": "¸Ô±ÈÑÇ",
    "englishName": "Gambia",
    "code": "+220"
  },
  {
    "id": 59,
    "chineseName": "¸ñÂ³¼ªÑÇ",
    "englishName": "Georgia",
    "code": "+995"
  },
  {
    "id": 60,
    "chineseName": "µÂ¹ú",
    "englishName": "Germany",
    "code": "+49"
  },
  {
    "id": 61,
    "chineseName": "¼ÓÄÉ",
    "englishName": "Ghana",
    "code": "+233"
  },
  {
    "id": 62,
    "chineseName": "Ö±²¼ÂÞÍÓ",
    "englishName": "Gibraltar",
    "code": "+350"
  },
  {
    "id": 63,
    "chineseName": "Ï£À°",
    "englishName": "Greece",
    "code": "+30"
  },
  {
    "id": 64,
    "chineseName": "¸ñÁÖÄÉ´ï",
    "englishName": "Grenada",
    "code": "+1809"
  },
  {
    "id": 65,
    "chineseName": "¹Øµº",
    "englishName": "Guam",
    "code": "+1671"
  },
  {
    "id": 66,
    "chineseName": "Î£µØÂíÀ­",
    "englishName": "Guatemala",
    "code": "+502"
  },
  {
    "id": 67,
    "chineseName": "¼¸ÄÚÑÇ",
    "englishName": "Guinea",
    "code": "+224"
  },
  {
    "id": 68,
    "chineseName": "¹çÑÇÄÇ",
    "englishName": "Guyana",
    "code": "+592"
  },
  {
    "id": 69,
    "chineseName": "º£µØ",
    "englishName": "Haiti",
    "code": "+509"
  },
  {
    "id": 70,
    "chineseName": "ºé¶¼À­Ë¹",
    "englishName": "Honduras",
    "code": "+504"
  },
  {
    "id": 71,
    "chineseName": "Ïã¸Û",
    "englishName": "Hongkong",
    "code": "+852"
  },
  {
    "id": 72,
    "chineseName": "ÐÙÑÀÀû",
    "englishName": "Hungary",
    "code": "+36"
  },
  {
    "id": 73,
    "chineseName": "±ùµº",
    "englishName": "Iceland",
    "code": "+354"
  },
  {
    "id": 74,
    "chineseName": "Ó¡¶È",
    "englishName": "India",
    "code": "+91"
  },
  {
    "id": 75,
    "chineseName": "Ó¡¶ÈÄáÎ÷ÑÇ",
    "englishName": "Indonesia",
    "code": "+62"
  },
  {
    "id": 76,
    "chineseName": "ÒÁÀÊ",
    "englishName": "Iran",
    "code": "+98"
  },
  {
    "id": 77,
    "chineseName": "ÒÁÀ­¿Ë",
    "englishName": "Iraq",
    "code": "+964"
  },
  {
    "id": 78,
    "chineseName": "°®¶ûÀ¼",
    "englishName": "Ireland",
    "code": "+353"
  },
  {
    "id": 79,
    "chineseName": "ÒÔÉ«ÁÐ",
    "englishName": "Israel",
    "code": "+972"
  },
  {
    "id": 80,
    "chineseName": "Òâ´óÀû",
    "englishName": "Italy",
    "code": "+39"
  },
  {
    "id": 81,
    "chineseName": "¿ÆÌØµÏÍß",
    "englishName": "Ivory Coast",
    "code": "+225"
  },
  {
    "id": 82,
    "chineseName": "ÑÀÂò¼Ó",
    "englishName": "Jamaica",
    "code": "+1876"
  },
  {
    "id": 83,
    "chineseName": "ÈÕ±¾",
    "englishName": "Japan",
    "code": "+81"
  },
  {
    "id": 84,
    "chineseName": "Ô¼µ©",
    "englishName": "Jordan",
    "code": "+962"
  },
  {
    "id": 85,
    "chineseName": "¼íÆÒÕ¯",
    "englishName": "Kampuchea (Cambodia )",
    "code": "+855"
  },
  {
    "id": 86,
    "chineseName": "¹þÈø¿ËË¹Ì¹",
    "englishName": "Kazakstan",
    "code": "+327"
  },
  {
    "id": 87,
    "chineseName": "¿ÏÄáÑÇ",
    "englishName": "Kenya",
    "code": "+254"
  },
  {
    "id": 88,
    "chineseName": "º«¹ú",
    "englishName": "Korea",
    "code": "+82"
  },
  {
    "id": 89,
    "chineseName": "¿ÆÍþÌØ",
    "englishName": "Kuwait",
    "code": "+965"
  },
  {
    "id": 90,
    "chineseName": "¼ª¶û¼ªË¹Ì¹",
    "englishName": "Kyrgyzstan",
    "code": "+331"
  },
  {
    "id": 91,
    "chineseName": "ÀÏÎÎ",
    "englishName": "Laos",
    "code": "+856"
  },
  {
    "id": 92,
    "chineseName": "À­ÍÑÎ¬ÑÇ",
    "englishName": "Latvia",
    "code": "+371"
  },
  {
    "id": 93,
    "chineseName": "Àè°ÍÄÛ",
    "englishName": "Lebanon",
    "code": "+961"
  },
  {
    "id": 94,
    "chineseName": "À³Ë÷ÍÐ",
    "englishName": "Lesotho",
    "code": "+266"
  },
  {
    "id": 95,
    "chineseName": "Àû±ÈÀïÑÇ",
    "englishName": "Liberia",
    "code": "+231"
  },
  {
    "id": 96,
    "chineseName": "Àû±ÈÑÇ",
    "englishName": "Libya",
    "code": "+218"
  },
  {
    "id": 97,
    "chineseName": "ÁÐÖ§¶ØÊ¿µÇ",
    "englishName": "Liechtenstein",
    "code": "+423"
  },
  {
    "id": 98,
    "chineseName": "Á¢ÌÕÍð",
    "englishName": "Lithuania",
    "code": "+370"
  },
  {
    "id": 99,
    "chineseName": "Â¬É­±¤",
    "englishName": "Luxembourg",
    "code": "+352"
  },
  {
    "id": 100,
    "chineseName": "°ÄÃÅ",
    "englishName": "Macao",
    "code": "+853"
  },
  {
    "id": 101,
    "chineseName": "Âí´ï¼ÓË¹¼Ó",
    "englishName": "Madagascar",
    "code": "+261"
  },
  {
    "id": 102,
    "chineseName": "ÂíÀ­Î¬",
    "englishName": "Malawi",
    "code": "+265"
  },
  {
    "id": 103,
    "chineseName": "ÂíÀ´Î÷ÑÇ",
    "englishName": "Malaysia",
    "code": "+60"
  },
  {
    "id": 104,
    "chineseName": "Âí¶û´ú·ò",
    "englishName": "Maldives",
    "code": "+960"
  },
  {
    "id": 105,
    "chineseName": "ÂíÀï",
    "englishName": "Mali",
    "code": "+223"
  },
  {
    "id": 106,
    "chineseName": "Âí¶úËû",
    "englishName": "Malta",
    "code": "+356"
  },
  {
    "id": 107,
    "chineseName": "ÂíÀïÑÇÄÇÈºµº",
    "englishName": "Mariana Is",
    "code": "+1670"
  },
  {
    "id": 108,
    "chineseName": "ÂíÌáÄá¿Ë",
    "englishName": "Martinique",
    "code": "+596"
  },
  {
    "id": 109,
    "chineseName": "Ã«ÀïÇóË¹",
    "englishName": "Mauritius",
    "code": "+230"
  },
  {
    "id": 110,
    "chineseName": "Ä«Î÷¸ç",
    "englishName": "Mexico",
    "code": "+52"
  },
  {
    "id": 111,
    "chineseName": "Ä¦¶û¶àÍß",
    "englishName": "Moldova Republic of",
    "code": "+373"
  },
  {
    "id": 112,
    "chineseName": "Ä¦ÄÉ¸ç",
    "englishName": "Monaco",
    "code": "+377"
  },
  {
    "id": 113,
    "chineseName": "ÃÉ¹Å",
    "englishName": "Mongolia",
    "code": "+976"
  },
  {
    "id": 114,
    "chineseName": "ÃÉÌØÈûÀ­ÌØµº",
    "englishName": "Montserrat Is",
    "code": "+1664"
  },
  {
    "id": 115,
    "chineseName": "Ä¦Âå¸ç",
    "englishName": "Morocco",
    "code": "+212"
  },
  {
    "id": 116,
    "chineseName": "ÄªÉ£±È¿Ë",
    "englishName": "Mozambique",
    "code": "+258"
  },
  {
    "id": 117,
    "chineseName": "ÄÉÃ×±ÈÑÇ",
    "englishName": "Namibia",
    "code": "+264"
  },
  {
    "id": 118,
    "chineseName": "è§Â³",
    "englishName": "Nauru",
    "code": "+674"
  },
  {
    "id": 119,
    "chineseName": "Äá²´¶û",
    "englishName": "Nepal",
    "code": "+977"
  },
  {
    "id": 120,
    "chineseName": "ºÉÊô°²µÄÁÐË¹",
    "englishName": "Netheriands Antilles",
    "code": "+599"
  },
  {
    "id": 121,
    "chineseName": "ºÉÀ¼",
    "englishName": "Netherlands",
    "code": "+31"
  },
  {
    "id": 122,
    "chineseName": "ÐÂÎ÷À¼",
    "englishName": "New Zealand",
    "code": "+64"
  },
  {
    "id": 123,
    "chineseName": "Äá¼ÓÀ­¹Ï",
    "englishName": "Nicaragua",
    "code": "+505"
  },
  {
    "id": 124,
    "chineseName": "ÄáÈÕ¶û",
    "englishName": "Niger",
    "code": "+227"
  },
  {
    "id": 125,
    "chineseName": "ÄáÈÕÀûÑÇ",
    "englishName": "Nigeria",
    "code": "+234"
  },
  {
    "id": 126,
    "chineseName": "³¯ÏÊ",
    "englishName": "North Korea",
    "code": "+850"
  },
  {
    "id": 127,
    "chineseName": "Å²Íþ",
    "englishName": "Norway",
    "code": "+47"
  },
  {
    "id": 128,
    "chineseName": "°¢Âü",
    "englishName": "Oman",
    "code": "+968"
  },
  {
    "id": 129,
    "chineseName": "°Í»ùË¹Ì¹",
    "englishName": "Pakistan",
    "code": "+92"
  },
  {
    "id": 130,
    "chineseName": "°ÍÄÃÂí",
    "englishName": "Panama",
    "code": "+507"
  },
  {
    "id": 131,
    "chineseName": "°Í²¼ÑÇÐÂ¼¸ÄÚÑÇ",
    "englishName": "Papua New Cuinea",
    "code": "+675"
  },
  {
    "id": 132,
    "chineseName": "°ÍÀ­¹ç",
    "englishName": "Paraguay",
    "code": "+595"
  },
  {
    "id": 133,
    "chineseName": "ÃØÂ³",
    "englishName": "Peru",
    "code": "+51"
  },
  {
    "id": 134,
    "chineseName": "·ÆÂÉ±ö",
    "englishName": "Philippines",
    "code": "+63"
  },
  {
    "id": 135,
    "chineseName": "²¨À¼",
    "englishName": "Poland",
    "code": "+48"
  },
  {
    "id": 137,
    "chineseName": "ÆÏÌÑÑÀ",
    "englishName": "Portugal",
    "code": "+351"
  },
  {
    "id": 138,
    "chineseName": "²¨¶àÀè¸÷",
    "englishName": "Puerto Rico",
    "code": "+1787"
  },
  {
    "id": 139,
    "chineseName": "¿¨Ëþ¶û",
    "englishName": "Qatar",
    "code": "+974"
  },
  {
    "id": 140,
    "chineseName": "ÁôÄáÍú",
    "englishName": "Reunion",
    "code": "+262"
  },
  {
    "id": 141,
    "chineseName": "ÂÞÂíÄáÑÇ",
    "englishName": "Romania",
    "code": "+40"
  },
  {
    "id": 142,
    "chineseName": "¶íÂÞË¹",
    "englishName": "Russia",
    "code": "+7"
  },
  {
    "id": 143,
    "chineseName": "Ê¥ÎÄÉ­ÌØµº",
    "englishName": "Saint Vincent",
    "code": "+1784"
  },
  {
    "id": 144,
    "chineseName": "¶«ÈøÄ¦ÑÇ(ÃÀ)",
    "englishName": "Samoa Eastern",
    "code": "+684"
  },
  {
    "id": 145,
    "chineseName": "Î÷ÈøÄ¦ÑÇ",
    "englishName": "Samoa Western",
    "code": "+685"
  },
  {
    "id": 146,
    "chineseName": "Ê¥ÂíÁ¦Åµ",
    "englishName": "San Marino",
    "code": "+378"
  },
  {
    "id": 147,
    "chineseName": "Ê¥¶àÃÀºÍÆÕÁÖÎ÷±È",
    "englishName": "Sao Tome and Principe",
    "code": "+239"
  },
  {
    "id": 148,
    "chineseName": "É³ÌØ°¢À­²®",
    "englishName": "Saudi Arabia",
    "code": "+966"
  },
  {
    "id": 149,
    "chineseName": "ÈûÄÚ¼Ó¶û",
    "englishName": "Senegal",
    "code": "+221"
  },
  {
    "id": 150,
    "chineseName": "ÈûÉà¶û",
    "englishName": "Seychelles",
    "code": "+248"
  },
  {
    "id": 151,
    "chineseName": "ÈûÀ­Àû°º",
    "englishName": "Sierra Leone",
    "code": "+232"
  },
  {
    "id": 152,
    "chineseName": "ÐÂ¼ÓÆÂ",
    "englishName": "Singapore",
    "code": "+65"
  },
  {
    "id": 153,
    "chineseName": "Ë¹Âå·¥¿Ë",
    "englishName": "Slovakia",
    "code": "+421"
  },
  {
    "id": 154,
    "chineseName": "Ë¹ÂåÎÄÄáÑÇ",
    "englishName": "Slovenia",
    "code": "+386"
  },
  {
    "id": 155,
    "chineseName": "ËùÂÞÃÅÈºµº",
    "englishName": "Solomon Is",
    "code": "+677"
  },
  {
    "id": 156,
    "chineseName": "Ë÷ÂíÀï",
    "englishName": "Somali",
    "code": "+252"
  },
  {
    "id": 157,
    "chineseName": "ÄÏ·Ç",
    "englishName": "South Africa",
    "code": "+27"
  },
  {
    "id": 158,
    "chineseName": "Î÷°àÑÀ",
    "englishName": "Spain",
    "code": "+34"
  },
  {
    "id": 159,
    "chineseName": "Ë¹ÀïÀ¼¿¨",
    "englishName": "Sri Lanka",
    "code": "+94"
  },
  {
    "id": 160,
    "chineseName": "Ê¥Â¬Î÷ÑÇ",
    "englishName": "St.Lucia",
    "code": "+1758"
  },
  {
    "id": 161,
    "chineseName": "ËÕµ¤",
    "englishName": "Sudan",
    "code": "+249"
  },
  {
    "id": 162,
    "chineseName": "ËÕÀïÄÏ",
    "englishName": "Suriname",
    "code": "+597"
  },
  {
    "id": 163,
    "chineseName": "Ë¹ÍþÊ¿À¼",
    "englishName": "Swaziland",
    "code": "+268"
  },
  {
    "id": 164,
    "chineseName": "Èðµä",
    "englishName": "Sweden",
    "code": "+46"
  },
  {
    "id": 165,
    "chineseName": "ÈðÊ¿",
    "englishName": "Switzerland",
    "code": "+41"
  },
  {
    "id": 166,
    "chineseName": "ÐðÀûÑÇ",
    "englishName": "Syria",
    "code": "+963"
  },
  {
    "id": 167,
    "chineseName": "Ì¨ÍåÊ¡",
    "englishName": "Taiwan",
    "code": "+886"
  },
  {
    "id": 168,
    "chineseName": "Ëþ¼ª¿ËË¹Ì¹",
    "englishName": "Tajikstan",
    "code": "+992"
  },
  {
    "id": 169,
    "chineseName": "Ì¹É£ÄáÑÇ",
    "englishName": "Tanzania",
    "code": "+255"
  },
  {
    "id": 170,
    "chineseName": "Ì©¹ú",
    "englishName": "Thailand",
    "code": "+66"
  },
  {
    "id": 171,
    "chineseName": "¶à¸ç",
    "englishName": "Togo",
    "code": "+228"
  },
  {
    "id": 172,
    "chineseName": "ÌÀ¼Ó",
    "englishName": "Tonga",
    "code": "+676"
  },
  {
    "id": 173,
    "chineseName": "ÌØÁ¢Äá´ïºÍ¶à°Í¸ç",
    "englishName": "Trinidad and Tobago",
    "code": "+1809"
  },
  {
    "id": 174,
    "chineseName": "Í»ÄáË¹",
    "englishName": "Tunisia",
    "code": "+216"
  },
  {
    "id": 175,
    "chineseName": "ÍÁ¶úÆä",
    "englishName": "Turkey",
    "code": "+90"
  },
  {
    "id": 176,
    "chineseName": "ÍÁ¿âÂüË¹Ì¹",
    "englishName": "Turkmenistan",
    "code": "+993"
  },
  {
    "id": 177,
    "chineseName": "ÎÚ¸É´ï",
    "englishName": "Uganda",
    "code": "+256"
  },
  {
    "id": 178,
    "chineseName": "ÎÚ¿ËÀ¼",
    "englishName": "Ukraine",
    "code": "+380"
  },
  {
    "id": 179,
    "chineseName": "°¢À­²®ÁªºÏÇõ³¤¹ú",
    "englishName": "United Arab Emirates",
    "code": "+971"
  },
  {
    "id": 180,
    "chineseName": "Ó¢¹ú",
    "englishName": "United Kiongdom",
    "code": "+44"
  },
  {
    "id": 181,
    "chineseName": "ÃÀ¹ú",
    "englishName": "United States of America",
    "code": "+1"
  },
  {
    "id": 182,
    "chineseName": "ÎÚÀ­¹ç",
    "englishName": "Uruguay",
    "code": "+598"
  },
  {
    "id": 183,
    "chineseName": "ÎÚ×È±ð¿ËË¹Ì¹",
    "englishName": "Uzbekistan",
    "code": "+233"
  },
  {
    "id": 184,
    "chineseName": "Î¯ÄÚÈðÀ­",
    "englishName": "Venezuela",
    "code": "+58"
  },
  {
    "id": 185,
    "chineseName": "Ô½ÄÏ",
    "englishName": "Vietnam",
    "code": "+84"
  },
  {
    "id": 186,
    "chineseName": "Ò²ÃÅ",
    "englishName": "Yemen",
    "code": "+967"
  },
  {
    "id": 187,
    "chineseName": "ÄÏË¹À­·ò",
    "englishName": "Yugoslavia",
    "code": "+381"
  },
  {
    "id": 189,
    "chineseName": "ÔúÒÁ¶û",
    "englishName": "Zaire",
    "code": "+243"
  },
  {
    "id": 190,
    "chineseName": "ÔÞ±ÈÑÇ",
    "englishName": "Zambia",
    "code": "+260"
  },
  {
    "id": 188,
    "chineseName": "½ò°Í²¼Î¤",
    "englishName": "Zimbabwe",
    "code": "+263"
  }
];

export default phoneCodes;
